.catalog {
  width: 100%;
  margin-top: 235px;
  background-color: $color-default-white;
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 101;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include vp--1279 {
    margin-top: 241px;
  }

  @include vp--959 {
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
    width: 320px;
    z-index: 1001;
    display: block;
    transform: translateX(-150%);
    transition: $trans--300;
  }

  @include vp--639 {
    width: 100%;
  }

  &__title {
    font-size: 34px;
    line-height: 40px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 33px;
    color: $color-forest-green;

    @include vp--959 {
      display: none;
    }

    &--mobile {
      display: none;

      @include vp--959 {
        display: block;
        margin: 0;
        width: 100%;
        height: 42px;
        padding: 11px 20px 9px;
        color: $color-black;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        background-color: $color-whisper;
        text-align: center;
      }
    }
  }

  &__main-menu {
    width: 260px;
    height: 100%;
    border-right: 1px solid $color-whisper;
    background-color: $color-default-white;
    // padding-right: 20px;
    padding-top: 53px;
    flex-shrink: 0;

    margin-left: auto;

    overflow-y: auto;

    @include vp--1279 {
      width: 290px;
      padding-left: 32px;
      padding-top: 47px;
    }

    @include vp--959 {
      width: 100%;
      border-right: none;
      border-bottom: 1px solid $color-whisper;
      position: relative;
      padding-right: 0;
      padding-left: 0;
      padding-top: 0;
    }
  }

  &__list--specials {
    padding-top: 20px;
    border-top: 1px solid $color-whisper;

    @include vp--959 {
      padding-top: unset;
      border-top: none;

      & svg {
        display: none;
      }

      & .item {
        border-bottom: none;
      }
    }
  }

  &__item.item {
    // margin-bottom: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
  }

  &__link.link {
    font-size: 16px;
    line-height: 26px;
    display: flex;

    &:active,
    &.active {
      color: $color-black;
      font-weight: 600;
    }

    @include vp--959 {
      font-weight: 600;
    }
  }

  &__icon {
    order: -5;
    margin-right: 10px;
    fill: $color-transparent;
  }

  &__arrow {
    display: none;

    margin-left: auto;
    width: 10px;
    height: 13px;
  }

  &__link--with-submenu .catalog__arrow {
    display: block;
  }

  &__arrow--mobile {
    display: none;

    @include vp--959 {
      display: block;
    }
  }

  &__inner-menu {
    position: relative;
    height: 100%;
    max-width: 950px;
    padding-left: 33px;
    padding-top: 53px;
    padding-bottom: 50px;
    flex-grow: 1;

    margin-right: auto;

    overflow-y: auto;

    @include vp--1279 {
      padding-top: 47px;
    }

    @include vp--959 {
      left: 0;
      width: 100%;
      padding-top: 0;
      padding-left: 0;
      padding-bottom: 0;
      margin-right: unset;
      background-color: $color-default-white;
    }
  }

  &__block {
    display: none;

    @include vp--959 {
      display: block;
    }

    &.active-menu {
      display: block;
    }
  }

  &__block-title {
    font-size: 34px;
    line-height: 40px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 33px;
    color: $color-nero;

    @include vp--959 {
      display: none;
    }
  }

  &__subblock {
    margin-bottom: 20px;
    break-inside: avoid-column;

    @include vp--959 {
      margin-bottom: 10px;
    }
  }

  &__subblock-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__subblock-wrapper {
    column-gap: 10px;
    columns: 3;

    @include vp--1279 {
      columns: 2;
    }

    @include vp--959 {
      columns: unset;
    }
  }

  &__subblock-link {
    font-size: 16px;
    line-height: 26px;
  }

  &__block,
  &__subblock-list {
    @include vp--959 {
      position: fixed;
      height: 100%;
      overflow-y: auto;

      left: 0;
      right: 0;
      // top: 0;
      top: 42px;
      bottom: 0;

      transition: $trans--300;
      transform: translateX(-100%);

      &.active-menu {
        transform: translateX(0);
      }
    }
  }

  &__subblock-list {
    @include vp--959 {
      top: 0;
    }
  }

  &.active {
    display: flex;

    @include vp--959 {
      display: block;
    }
  }

  &.active-menu {
    transform: translateX(0);
  }

  .close-button {
    top: 53px;
    right: 53px;
    color: $color-default-black;
    z-index: 10;
    transition: color 0.3s;

    &:hover {
      color: $color-dimgray;
      transition: color 0.3s;
    }

    @include vp--1279 {
      top: 30px;
      right: 30px;
    }

    @include vp--959 {
      width: 42px;
      height: 42px;
      top: 0;
      right: 10px;

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }

  .hidden-prev {
    display: none;
  }

  &__subblock-item {
    margin-bottom: 8px;

    @include vp--959 {
      margin-bottom: 0;
    }
  }

  &__item,
  &__subblock,
  &__subblock-item {
    @include vp--959 {
      border-bottom: 1px solid $color-whisper;
    }
  }

  .item {
    @include vp--959 {
      margin-bottom: 0;
      padding-bottom: 0;
      padding-right: 3px;
      // border-bottom: 1px solid $color-whisper;
    }
  }

  &__link,
  &__subblock-title,
  &__subblock-link {
    @include vp--959 {
      padding-bottom: 15px;
    }
  }

  &__link--specials {
    @include vp--959 {
      padding-bottom: 0;
    }
  }

  .link {
    @include vp--959 {
      font-size: 14px;
      line-height: 22px;
      font-weight: 500;
      margin-bottom: 0;
      // padding-bottom: 15px;
      padding-top: 15px;
      padding-right: 5px;
      height: 100%;
      width: 100%;

      &:active {
        color: $color-forest-green;
      }
    }
  }

  &__list--specials,
  &__block,
  &__subblock-list {
    @include vp--959 {
      padding-bottom: 40px;
    }
  }

  .list {
    @include vp--959 {
      display: flex;
      flex-direction: column;
      background-color: $color-default-white;

      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 12px;
      // padding-bottom: 40px;
    }
  }
}
