.buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.buttons__background {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content ;
  background-color: #362E2E;
  padding: 10px;
}
.buttons__background .button {
  margin: 5px;
}

.buttons > * {
  margin-right: 15px;
  margin-bottom: 15px;
}

.buttons h2,
.buttons hr {
  margin-right: 0;
  width: 100%;
}

.buttons hr {
  border: 1px solid #ECEBEB;
}

.buttons.catalog-buttons hr {
  border: 1px solid rgba(10, 10, 10, 0.05);
}

.buttons.catalog-buttons hr {
  margin-top: 50px;
  margin-bottom: 40px;
}

@media (max-width: 959px) {
  .buttons.catalog-buttons .button {
    text-align: left;
  }

  .buttons.catalog-buttons > * {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .buttons.catalog-buttons hr {
    margin-top: 40px;
  }
}