.search {
  display: flex;
  position: relative;
  margin-right: 30px;

  @include vp--1279 {
    margin-right: 16px;
  }

  @include vp--959 {
    grid-area: search;
    margin-right: 0;

    div {
      width: 100%;
    }
  }

  &__dropdown-wrap {
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    width: 100%;
    overflow: hidden;
    height: auto;
    transition: $trans--300;
    border-radius: 5px;
    pointer-events: none;

    @media (max-height: 700px) {
      height: 60vh;
    }

    @include vp--639 {
      height: 30vh;
    }

    &--active.search__dropdown-wrap {
      pointer-events: auto;
    }

    &--active .search__dropdown {
      transform: translateY(0);
    }
  }

  &__dropdown {
    position: relative;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 24px;
    padding-bottom: 15px;
    background-color: $color-default-white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: auto;
    transform: translateY(-100%);
    transition: $trans--300;

    // max-height: 442px;
    max-height: 100%;

    -ms-overflow-style: none;
    scrollbar-width: none;

    @include vp--959 {
      padding-top: 19px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &--hidden {
      visibility: hidden;
    }

    &--active {
      // transform: translateY(0);
    }
  }

  &__dropdown-text {
    font-size: 16px;
    font-weight: 600;

    margin: 0;
    padding-top: 20px;
    padding-bottom: 7px;
    padding-left: 20px;
    border-top: 1px solid $color-whisper;

    @include vp--959 {
      padding-top: 19px;
      padding-bottom: 8px;
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__field {
    display: flex;

    @include vp--959 {
      width: 100%;

      div {
        width: 100%;
      }
    }

    @include vp--639 {
      order: 1;
      width: unset;
      margin-left: 30px;
    }
  }

  &__dropdown-item {
    &:hover {
      background-color: rgba($color: $color-christi, $alpha: 0.1);
    }
  }

  &__dropdown-link,
  &__dropdown-categories-link {
    display: inline-block;
    height: 100%;
    width: 100%;
    padding: 10px 20px;
    font-size: 16px;
    line-height: 20px;

    @include vp--959 {
      font-size: 14px;
      line-height: 18px;
    }

    b {
      font-weight: 500;
    }
  }

  &__dropdown-categories-item {
    position: relative;

    &::after {
      position: absolute;
      display: block;
      content: "";
      background-image: url("data:image/svg+xml,%3Csvg width='9' height='14' viewBox='0 0 9 14' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.58586 7.00001L0.292969 1.70712L1.70718 0.292908L8.41429 7.00001L1.70718 13.7071L0.292969 12.2929L5.58586 7.00001Z' fill='currentColor'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      top: 25px;
      right: 10px;
      width: 20px;
      height: 20px;
    }
  }

  &__dropdown-categories-link {
    font-size: 16px;
    font-weight: 500;

    display: flex;
    flex-wrap: wrap;
  }

  &__dropdown-categories-text {
    padding-top: 2px;
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 95%;

    @include vp--1279 {
      padding-top: 4px;
    }

    @include vp--959 {
      width: 96%;
      padding-top: 2px;
      font-size: 13px;
      line-height: 20px;
    }
  }

  li {
    margin-right: 0;
  }

  .search__dropdown-list {
    padding-bottom: 10px;
  }
}
