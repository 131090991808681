.basket {
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;
  margin-bottom: 50px;
  border-bottom: 1px solid $color-whisper;

  @include vp--1279 {
    flex-direction: column;
    align-items: baseline;
  }

  .basket-inner {
    @include vp--1279 {
      margin-bottom: 50px;
    }

    .basket-title {
      font-weight: bold;
      font-size: 22px;
      line-height: 28px;
      color: $color-black;
      margin-bottom: 50px;
    }

    .basket-table {
      .basket-row {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $color-whisper;
        padding-bottom: 15px;
        margin-bottom: 20px;
        width: 100%;
        padding-right: 40px;
        position: relative;
        justify-content: space-between;

        &:last-of-type {
          margin-bottom: 0;
        }

        @include vp--1279 {
          flex-wrap: wrap;
        }

        @include vp--959 {
          padding-bottom: 0;
        }

        @include vp--639 {
          padding-right: 0;
          justify-content: flex-start;
        }

        .row-item {
          @include vp--959 {
            margin-bottom: 15px;
          }

          @include vp--639 {
          }
        }

        .row-item-name {
          display: flex;
          align-items: center;
          max-width: 330px;
          margin-right: auto;

          @include vp--767 {
            min-width: 80%;
          }

          @include vp--639 {
            min-width: 60%;
            align-items: flex-start;
          }

          .row-item-img {
            width: 79px;
            height: 67px;
            margin-right: 10px;
  
            @include vp--959 {
              width: 67px;
              height: 66px;
            }
  
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .item-name {
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #0A0A0A;
            margin-bottom: 5px;

            @include vp--959 {
              font-size: 14px;
            }

            @include vp--639 {
              max-width: 180px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -moz-box;
              -moz-box-orient: vertical;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              line-clamp: 3;
              box-orient: vertical;
              margin-bottom: 11px;
            }
          }

          .item-code {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #696969;

            @include vp--959 {
              font-size: 13px;
              line-height: 18px;
            }
          }
        }

        .row-item-selects {
          display: flex;
          margin-right: 25px;
          margin-left: 20px;

          @include vp--767 {
            margin-left: 0;
          }

          @include vp--639 {
            margin-right: auto;
          }

          .custom-select {
            width: 123px;
            height: 56px;
            margin-top: 0;
            border: 1px solid #ecebeb;
            border-radius: 2px;

            @include vp--959 {
              height: 52px;
            }

            @include vp--639 {
              width: 138px;
            }

            .custom-select__button {
              background: transparent;
              padding: 5px 10px;

              .custom-select__icon {
                background-color: black;
              }
            }

            .custom-select__placeholder {
              color: #000000;
              font-weight: normal;
              font-size: 18px;
              line-height: 30px;

              @include vp--959 {
                font-size: 16px;
                line-height: 28px;
              }
            }
          }
        }

        .row-item-value {
          margin-right: 25px;
          margin-left: auto;

          @include vp--639 {
            margin-left: unset;
          }

          .card-popup__count {
            border: 1px solid #ecebeb;
            border-radius: 2px;
            height: 56px;
            margin-top: 0;
            margin-bottom: 0;

            @include vp--959 {
              height: 52px;
            }

            .button,
            .custom-input input {
              background: transparent;
              color: #000000;
            }

            .button--icon {
              width: 30px;
              height: 56px;

              @include vp--959 {
                height: 52px;
              }
            }

            .custom-input input {
              width: 28px;
              padding: 0;
              height: 56px;

              @include vp--959 {
                height: 52px;
              }

              @include vp--639 {
                width: 56px;
              }
            }
          }
        }

        .row-item-price {
          .item-price {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #0A0A0A;
            margin-bottom: 8px;

            @include vp--959 {
              font-size: 14px;
              line-height: 22px;
            }
          }

          .item-bonus {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #696969;

            @include vp--959 {
              font-size: 13px;
              line-height: 18px;
            }
          }
        }

        .row-item-cross {
          position: absolute;
          right: 0;
          top: 12px;
          background: transparent;
          border: none;
          outline: none;
          cursor: pointer;
          margin-bottom: 25px;

          @include vp--639 {
            top: 2px;
          }
        }
      }
    }
  }

  .orders-basket-wrapper {
    @include vp--1279 {
      width: 485px;

      .orders-basket {
        max-width: 485px;
      }

      .orders-basket-agreement {
        max-width: 350px;
      }
    }

    @include vp--767 {
      width: 100%;

      .orders-basket {
        max-width: 100%;
      }

      .orders-basket-agreement {
        max-width: 100%;
      }
    }
  }
}
