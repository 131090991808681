.product-card {
  position: relative;
  display: block;
  width: 286px;
  padding-bottom: 10px;
  outline: none;
  cursor: pointer;
  transition: border $trans--300;

  @include vp--1279 {
    width: 271px;
  }

  @include vp--959 {
    width: 250px;
  }

  .button--cart {
    width: 143px;
    display: flex;
    flex-direction: row-reverse;
    gap: 9px;

    @include vp--1279 {
      width: 139px;
    }

    @include vp--959 {
      width: 133px;
      height: 46px;
      padding: 12px 8px;
      margin-right: 2.5px;
    }
  }

  .button--icon {
    padding: 5px 5px;
  }

  &.in-cart {
    .button--cart {
      background-color: $color-camarone;
      transition: all $trans--300;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          background-color: $color-forest-green-2;
        }

        &:active {
          background-color: $color-camarone;
        }
      }
    }
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover .product-card__wrap,
    &:focus .product-card__wrap {
      border: 1px solid rgba($color: $color-forest-green-3, $alpha: 0.1);
    }

    &:hover .product-card__service,
    &:focus .product-card__service {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
  }
}

.product-card__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.product-card__wrap {
  padding: 34px 15px 15px;
  border: 1px solid $color-transparent;
  border-radius: 2px;
  transition: border-color $trans--300;

  @include vp--1279 {
    padding: 15px 15px;
  }

  @include vp--959 {
    padding: 15px 7.5px;
  }
}

.product-card__block {
  display: flex;
  flex-direction: column;
  margin-bottom: 7px;

  @include vp--959 {
    margin-bottom: 2px;
  }

  &--min-height {
    min-height: 93px;
    margin-bottom: 0;

    @include vp--1279 {
      min-height: 100px;
    }

    @include vp--959 {
      min-height: 80px;
    }
  }
}

.product-card__img {
  margin: 0 auto;
  margin-bottom: 26px;
  width: 100%;
  height: 218px;
  overflow: hidden;

  @include vp--1279 {
    height: 237px;
    margin-bottom: 7px;
  }

  @include vp--959 {
    height: 187px;
    margin-bottom: 14px;
  }

  @include vp--639 {
    margin-bottom: 18px;

    img {
      max-width: 86%;
    }
  }

  picture,
  img {
    object-fit: contain;
    height: 100%;
  }
}

.product-card__price {
  position: relative;
  margin: 0;
  margin-bottom: 5px;
  font-size: 26px;
  line-height: 34px;
  font-weight: 700;
  color: $color-black;

  &--red-color {
    color: $color-harley-davidson-orange;
  }

  @include vp--959 {
    margin-bottom: 2px;
    font-size: 24px;
  }
}

.product-card__price-sale {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $color-black;
  text-decoration: line-through;
  text-decoration-color: $color-harley-davidson-orange;

  @include vp--959 {
    font-size: 13px;
    line-height: 18px;
  }
}

.product-card__price-label {
  position: absolute;
  top: -36px;
  left: 0;
  padding: 2px 8px 3px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  background-color: $color-pumpkin;
  border-radius: 2px;
  z-index: 2;

  @include vp--959 {
    font-size: 13px;
    line-height: 18px;
  }

  @include vp--639 {
    top: -32px;
  }

  &--sale {
    color: $color-black;
    background-color: $color-gold;
  }
}

.product-card__point {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  color: $color-forest-green;

  @include vp--959 {
    font-size: 13px;
    line-height: 18px;
  }
}

.product-card__title {
  margin: 0;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  @include vp--1279 {
    margin-bottom: 6px;
  }

  @include vp--959 {
    font-size: 14px;
    line-height: 20px;
    -webkit-line-clamp: 2;
  }
}

.product-card__number,
.product-card__number a {
  font-size: 14px;
  line-height: 20px;
  color: $color-very-light-grey;

  @include vp--959 {
    font-size: 13px;
    line-height: 18px;
  }
}

.product-card__count {
  display: flex;
  height: 50px;
  margin-top: 14px;

  @include vp--1279 {
    margin-top: 8px;
  }

  @include vp--959 {
    height: 46px;
  }

  .button--cart {
    margin-left: auto;
  }
}

.product-card__form {
  position: relative;
  z-index: 2;

  .custom-input input {
    width: 40px;
    height: 50px;
    padding: 8px 1px;

    @include vp--1279 {
      width: 30px;
    }

    @include vp--959 {
      height: 46px;
    }
  }
}

.product-card__service {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity $trans--300,
    visibility $trans--300;

  .button--service {
    transition: opacity $trans--300;
  }
}
