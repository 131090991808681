.card-popup {
  display: grid;
  grid-template-columns: auto 207px;
  column-gap: 32px;
  padding: 10px 20px;
  border-radius: 2px;

  position: absolute;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: all $trans--300;

  @include vp--767 {
    padding: 20px;
  }

  @include vp--639 {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    height: 100%;
  }

  .button--price {
    padding: 12px 5px;

    @include vp--639 {
      padding: 5px 5px;
    }
  }
}

.card-popup__pack {
  position: relative;
  background-color: $color-default-white;

  @include vp--639 {
    padding-top: 10px;

    &:before {
      content: "";
      position: absolute;
      top: -10px;
      left: -20px;
      width: calc(100% + 40px);
      height: 1px;
      background-color: $color-snow;
    }
  }

  .js-card-popup-btn {
    opacity: 0;
    color: $color-default-white;
    visibility: hidden;
    pointer-events: none;
    transition: opacity $trans--300,
      visibility $trans--300;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-default-white;
      }
    }

    &.is-active {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
  }
}

.card-popup__main-title {
  margin: 0;
  margin-bottom: 11px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;

  @include vp--639 {
    width: 70%;
    font-size: 13px;
    line-height: 20px;
  }
}

.card-popup__list-title {
  margin: 0;
  margin-bottom: 3px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.4px;
  cursor: default;

  @include vp--639 {
    font-size: 11px;
    line-height: 15px;
  }
}

.card-popup__list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  list-style: none;

  @include vp--639 {
    margin-bottom: 16px;
  }

  &:last-of-type {
    margin-bottom: 0;

    @include vp--639 {
      margin-bottom: 15px;
    }
  }
}

.card-popup__item {
  width: calc(100% / 3 - 4px);
}

.card-popup__item-weight {
  font-size: 10px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 5px;

  @include vp--639 {
    margin-bottom: 0;
  }
}

.card-popup__result {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 15px;

  @include vp--639 {
    align-items: center;
    margin-bottom: 12px;
  }
}

.card-popup__result-text {
  font-size: 13px;
  line-height: 26px;
  font-weight: 500;

  @include vp--639 {
    margin-top: 9px;
    font-size: 11px;
    line-height: 15px;
  }
}

.card-popup__result-sum {
  font-size: 26px;
  line-height: 34px;
  font-weight: 700;

  @include vp--639 {
    font-size: 20px;
    line-height: 28px;
  }
}

.card-popup__form {
  position: relative;
  z-index: 2;
  padding-right: 8px;

  @include vp--639 {
    padding-right: 0;
  }

  button {
    width: 100%;

    @include vp--639 {
      height: 50px;
      font-size: 14px;
      line-height: 22px;
      padding: 9px 20px;
    }
  }

  .button--icon {
    width: 50px;

    @include vp--639 {
      height: 46px;
    }
  }
}

.card-popup__count {
  display: flex;
  height: 50px;
  margin-top: 14px;
  margin-bottom: 16px;

  @include vp--639 {
    margin-bottom: 11px;
  }

  .button--cart {
    margin-left: auto;
  }
}

.card-popup__block-wrap {
  padding-top: 57px;

  @include vp--639 {
    position: relative;
    display: grid;
    column-gap: 5px;
    grid-template-columns: 96px auto;
    padding-top: 26px;
    padding-bottom: 16px;
  }
}

.card-popup__block {
  display: flex;
  flex-direction: column;
  margin-bottom: 3px;

  &--min-height {
    min-height: 93px;
    margin-bottom: 0;

    @include vp--1279 {
      min-height: 100px;
    }

    @include vp--959 {
      min-height: 80px;
      margin-bottom: 2px;
    }

    @include vp--639 {
      min-height: unset;
    }
  }
}

.card-popup__price {
  position: relative;
  margin: 0;
  margin-bottom: 2px;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  color: $color-black;

  @include vp--639 {
    position: unset;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: -2px;
  }

  &--red-color {
    color: $color-harley-davidson-orange;
  }
}

.card-popup__price-sale {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $color-black;
  text-decoration: line-through;
  text-decoration-color: $color-harley-davidson-orange;

  @include vp--959 {
    font-size: 13px;
    line-height: 18px;
  }

  @include vp--639 {
    font-size: 10px;
    line-height: 16px;
  }
}

.card-popup__price-label {
  position: absolute;
  top: -36px;
  left: 0;
  padding: 2px 8px 3px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  background-color: $color-pumpkin;
  border-radius: 2px;
  z-index: 2;

  @include vp--959 {
    font-size: 13px;
    line-height: 18px;
  }

  @include vp--639 {
    top: unset;
    bottom: 15px;
    padding: 2px 7px 3px;
    font-size: 10px;
    line-height: 16px;
  }

  &--sale {
    color: $color-black;
    background-color: $color-gold;
  }
}

.card-popup__point {
  margin: 0;
  font-size: 13px;
  line-height: 18px;
  color: $color-forest-green;
  letter-spacing: 0.5px;

  @include vp--959 {
    font-size: 13px;
  }

  @include vp--639 {
    font-size: 10px;
    line-height: 12px;
  }
}

.card-popup__title {
  margin: 0;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @include vp--1279 {
    margin-bottom: 6px;
  }

  @include vp--959 {
    font-size: 14px;
  }

  @include vp--639 {
    margin-bottom: 0;
    font-size: 13px;
    line-height: 18px;
  }
}

.card-popup__number,
.card-popup__number a {
  margin-top: 4px;
  font-size: 13px;
  line-height: 18px;
  color: $color-very-light-grey;
  letter-spacing: 1px;

  @include vp--639 {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.6px;
  }
}

.card-popup__img {
  margin: 0 auto;
  margin-bottom: 20px;
  width: 100%;
  height: 180px;
  overflow: hidden;

  @include vp--1279 {
    margin-bottom: 7px;
  }

  @include vp--959 {
    margin-bottom: 14px;
  }

  @include vp--639 {
    margin-bottom: 0;
    height: 96px;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  picture {
    object-fit: contain;
    max-height: 100%;

    @include vp--959 {
      height: auto;
    }
  }
}
