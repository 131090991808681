.favorites-items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;

  .favorites-card {
    margin: 0 5px;
  }

  @include vp--959 {
    .product-card {
      width: 180px;
      margin-right: 10px;
      margin-bottom: 50px;

      .product-card__wrap {
        display: flex;
        flex-direction: column;

        .product-card__img {
          height: 180px;
          margin-bottom: 20px;
        }

        .product-card__block {
          min-height: unset;

          .product-card__price {
            font-size: 24px;

            .product-card__price-sale {
              display: block;
              margin-left: 0;
            }
          }

          .product-card__point,
          .product-card__number {
            font-size: 13px;
            line-height: 18px;
          }

          .product-card__title {
            font-size: 14px;
            line-height: 22px;
          }
        }

        .product-card__form {
          .product-card__count {
            margin-top: 5px;

            .button--minus-small-size,
            .button--plus-small-size {
              width: 25px;
              height: 46px;
            }

            .custom-input input {
              height: 46px;
            }
          }

          .button--cart {
            font-size: 14px;
            height: 44px;
            white-space: nowrap;
            max-width: 94px;

            svg {
              display: none;
            }
          }
        }
      }
    }
  }

  @include vp--639 {
    .product-card {
      width: 130px;
      margin-right: 10px;
      margin-bottom: 60px;

      .product-card__wrap {
        padding: 0;

        .product-card__img {
          height: 121px;
          margin-bottom: 16px;
        }

        .product-card__block {
          .product-card__price {
            font-size: 18px;
            line-height: 24px;

            .product-card__price-sale {
              font-size: 12px;
            }
          }

          .product-card__point,
          .product-card__number {
            font-size: 10px;
            line-height: 12px;
          }

          .product-card__title {
            font-size: 13px;
            line-height: 18px;
          }
        }

        .product-card__form {
          .product-card__count {
            flex-wrap: wrap;
            margin-top: 10px;

            .button--minus-small-size,
            .button--plus-small-size {
              width: 38px;
            }

            .custom-input input {
              width: 52px;
            }
          }

          .favorites-btn {
            width: 100%;
            max-width: unset;
            margin-top: 5px;
          }
        }
      }
    }
  }
}

.favorites-card {
  .custom-input {
    input {
      width: 135px;

      @include vp--1279 {
        width: 120px;
      }

      @include vp--959 {
        width: 55px;
      }
    }
  }

  .favorites-btn {
    margin-left: auto;
    font-size: 0;

    @include vp--639 {
      font-size: 14px;
      line-height: 22px;

      svg {
        margin-right: 10px;
      }
    }
  }
}

.favorites-title,
.favorites-subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .button {
    display: flex;
    align-items: center;

    svg {
      margin-right: 12px;
    }
  }
}

.favorites-logo {
  display: block;
  margin-top: 64px;
  margin-bottom: 90px;

  @include vp--1023 {
    width: 80px;
    height: 56px;
    margin-bottom: 60px;
  }

  @include vp--639 {
    margin: 0 auto;
    margin-top: 37px;
    margin-bottom: 60px;
    width: 50px;
    height: 35px;
  }
}

.favorites-title {
  margin-bottom: 50px;

  @include vp--959 {
    margin-bottom: 30px;
  }

  @include vp--639 {
    margin-bottom: 20px;
  }

  &.favorites-empty-title {
    margin-bottom: 30px;

    @include vp--959 {
      margin-bottom: 20px;
    }
  }

  span {
    font-weight: bold;
    font-size: 34px;
    line-height: 40px;
    color: $color-black;

    @include vp--959 {
      font-size: 28px;
      line-height: 34px;
    }

    @include vp--639 {
      font-size: 22px;
      line-height: 30px;
    }
  }

  .button {
    @include vp--959 {
      font-size: 14px;
      line-height: 22px;
      height: 40px;
    }

    @include vp--639 {
      display: none;
    }
  }
}

.favorites-subtitle {
  margin-bottom: 40px;

  @include vp--639 {
    margin-bottom: 30px;
  }

  span {
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    color: $color-black;

    @include vp--959 {
      font-size: 20px;
      line-height: 26px;
    }

    @include vp--639 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 15px;
    }
  }

  .button {
    display: none;

    @include vp--959 {
      font-size: 14px;
      line-height: 22px;
      height: 40px;
    }

    @include vp--639 {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
}

.favorites-text {
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: $color-black;
  margin-bottom: 30px;

  @include vp--959 {
    font-size: 16px;
    line-height: 28px;
  }

  @include vp--639 {
    font-size: 14px;
    line-height: 24px;
  }
}

.favorites-empty-btn {
  @include vp--639 {
    width: 100%;
    font-size: 14px;
    line-height: 14px;
    height: 40px;
  }
}

.favorites-order {
  display: flex;

  @include vp--959 {
    flex-direction: column;
  }

  .favorites-order-left {
    width: 100%;
    max-width: 730px;
    margin-right: 20px;

    @include vp--959 {
      max-width: unset;
      margin-bottom: 30px;
      margin-right: 0;
    }

    @include vp--639 {
      margin-bottom: 20px;
    }

    .accordeon {
      border: 1px solid $color-mercury;
      border-radius: 2px;
      padding: 40px;
      margin-bottom: 20px;

      @include vp--959 {
        padding: 30px;
      }

      @include vp--639 {
        padding: 20px;
        margin-bottom: 10px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .accordeon-title {
        &.active {
          .title {
            .arrow {
              transform: rotate(180deg);
              transition: transform 0.3s;
            }
          }
        }

        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          transition: 0.3s;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          span {
            font-weight: bold;
            font-size: 22px;
            line-height: 28px;

            @include vp--959 {
              font-size: 20px;
              line-height: 26px;
            }

            @include vp--639 {
              font-size: 18px;
              line-height: 24px;
            }
          }

          .arrow {
            width: 22px;
            min-width: 22px;
            height: 22px;
            background: $color-forest-green;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: transform 0.3s;

            svg {
              width: 100%;
            }

            @include vp--959 {
              min-width: 20px;
              width: 20px;
              height: 20px;
            }

            @include vp--639 {
              min-width: 18px;
              width: 18px;
              height: 18px;

              svg {
                width: 40%;
              }
            }
          }
        }

        .subtitle {
          font-weight: normal;
          font-size: 16px;
          line-height: 26px;
          color: $color-default-black;
          cursor: pointer;

          @include vp--959 {
            font-size: 14px;
            line-height: 24px;
          }

          @include vp--639 {
            font-size: 13px;
            line-height: 21px;
          }
        }
      }

      .accordeon-body {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
        padding-top: 20px;

        @include vp--959 {
          padding-top: 15px;
        }

        @include vp--639 {
          padding-top: 20px;
        }

        &.overflow-none {
          overflow: unset;
        }

        .btns-wrapper {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;

          @include vp--959 {
            margin-bottom: 15px;
          }

          @include vp--639 {
            flex-direction: column;
            margin-bottom: 10px;
          }

          .favorites-order-big-btn {
            width: 100%;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: $color-black;
            border: 1px solid $color-whisper;
            border-radius: 2px;
            outline: none;
            background: transparent;
            cursor: pointer;
            transition: 0.3s;

            @include vp--959 {
              height: 60px;
              font-size: 14px;
              line-height: 22px;
            }

            @include vp--639 {
              height: 52px;
              font-size: 13px;
              line-height: 20px;
            }

            &:hover {
              border: 1px solid $color-very-light-grey;
              transition: 0.3s;
            }

            &.active {
              border: 2px solid $color-forest-green;
            }

            &:not(:last-child) {
              margin-right: 10px;

              @include vp--639 {
                margin-right: 0;
                margin-bottom: 10px;
              }
            }
          }

          &.btns-wrapper-many {
            flex-wrap: wrap;
            justify-content: flex-start;
            margin: 0 -5px;
            margin-bottom: 20px;

            @include vp--639 {
              flex-direction: row;
            }

            .favorites-order-big-btn {
              padding: 15px;
              max-width: 209px;
              margin-bottom: 10px;
              margin-right: 5px;
              margin-left: 5px;
              text-align: center;

              @include vp--1023 {
                max-width: 159px;
              }

              @include vp--959 {
                max-width: 166px;
              }

              @include vp--639 {
                max-width: 114px;
                height: 60px;
              }
            }
          }
        }

        .inputs-wrapper {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;

          @include vp--639 {
            flex-direction: column;
            margin-bottom: 10px;
          }

          &:last-of-type {
            margin-bottom: 30px;

            @include vp--639 {
              margin-bottom: 20px;
            }
          }

          &.selects-wrapper {
            .input-item {
              display: flex;
              align-items: center;

              .favorites-select {
                display: inline-block;
                max-width: 140px;
                margin-right: 36px;
                position: relative;

                &:last-child {
                  margin-right: 0;
                }

                &:first-child::after {
                  content: "";
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  right: -27px;
                  background: black;
                  height: 2px;
                  width: 16px;
                }
              }
            }
          }

          .input-item {
            width: 100%;

            &.input-item-single {
              max-width: unset;
            }

            &:not(:last-child) {
              margin-right: 10px;

              @include vp--639 {
                margin-right: 0;
                margin-bottom: 10px;
              }
            }

            .label {
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              color: $color-dimgray;
              margin-bottom: 5px;

              @include vp--639 {
                font-size: 13px;
                line-height: 18px;
              }
            }

            input {
              width: 100%;
              height: 54px;
              font-weight: normal;
              font-size: 16px;
              line-height: 26px;
              color: $color-black;

              @include vp--959 {
                height: 46px;
              }

              @include vp--639 {
                font-size: 14px;
                line-height: 24px;
              }
            }

            .custom-select {
              width: 100%;

              .custom-select__placeholder {
                color: $color-black;
                font-weight: normal;
                font-size: 16px;
                line-height: 26px;

                @include vp--639 {
                  left: 15px;
                  transform: translate(0, -50%);
                  font-size: 14px;
                  line-height: 24px;
                }
              }

              .custom-select__button {
                background: transparent;
                border: 1px solid $color-whisper;
                padding: 5px 15px;
                transition: 0.3s;

                @include vp--959 {
                  height: 46px;
                }

                &:hover {
                  border: 1px solid $color-very-light-grey;
                  transition: 0.3s;
                }

                .custom-select__text {
                  color: $color-black;
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 26px;

                  @include vp--639 {
                    font-size: 14px;
                    line-height: 24px;
                  }
                }

                .custom-select__icon {
                  background-color: $color-black;
                  margin-left: auto;
                }
              }
            }
          }
        }

        .new-member {
          display: block;
          background: transparent;
          border: none;
          outline: none;
          cursor: pointer;
          font-weight: normal;
          font-size: 16px;
          line-height: 26px;
          color: $color-dimgray;
          margin-bottom: 30px;
          opacity: 1;
          transition: 0.3s;

          @include vp--639 {
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 20px;
          }

          &:hover {
            opacity: 0.7;
            transition: 0.3s;
          }

          svg {
            margin-right: 9px;
          }
        }

        .acc-btn {
          @include vp--959 {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            font-size: 14px;
            line-height: 22px;
          }
        }

        .textarea-input {
          width: 100%;
          min-height: 100px;
          margin-bottom: 10px;
          border: 1px solid $color-whisper;
          border-radius: 2px;
          padding: 8px 15px;
          transition: 0.3s;

          &:hover {
            background-color: $color-saltpan;
            transition: 0.3s;
          }

          &:focus-visible {
            border: 1px solid $color-forest-green-2;
            outline: none;
          }
        }

        .acc-info-text {
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: $color-dimgray;
          margin-bottom: 30px;

          @include vp--959 {
            font-size: 13px;
            line-height: 18px;
            margin-bottom: 20px;
          }
        }

        iframe {
          width: 100%;
          height: 250px;
          margin-bottom: 30px;

          @include vp--959 {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  .orders-basket {
    @include vp--959 {
      max-width: unset;
      padding: 30px;
    }

    @include vp--639 {
      padding: 20px;
    }
  }
}

.orders-basket-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.orders-basket-downtext {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: $color-black;
  margin-top: 10px;
  text-align: center;

  @include vp--959 {
    font-size: 14px;
    line-height: 20px;
  }

  @include vp--639 {
    font-size: 13px;
    line-height: 18px;
  }
}

.orders-basket {
  width: 100%;
  height: max-content;
  max-width: 300px;
  border: 1px solid $color-mercury;
  border-radius: 2px;
  padding: 20px;

  .orders-basket-oldprice {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $color-dimgray;
    margin-bottom: 5px;

    @include vp--959 {
      font-size: 13px;
      line-height: 18px;
    }
  }

  .orders-basket-price {
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;

    .orders-basket-price-number {
      font-weight: 800;
      font-size: 34px;
      line-height: 40px;
      color: $color-black;
    }

    .orders-basket-price-amount {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $color-black;
    }
  }

  .orders-basket-amount {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $color-dimgray;
    margin-bottom: 10px;

    @include vp--959 {
      font-size: 13px;
      line-height: 18px;
    }

    span {
      font-weight: bold;
      font-size: 18px;

      @include vp--959 {
        font-size: 17px;
      }
    }
  }

  .orders-basket-points {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7px;

    &:last-of-type {
      margin-bottom: 17px;
    }

    .orders-basket-points-text {
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: $color-night-rider;

      @include vp--959 {
        font-size: 14px;
        line-height: 20px;
      }

      @include vp--639 {
        font-size: 13px;
        line-height: 18px;
      }
    }

    .orders-basket-points-number {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $color-black;

      @include vp--959 {
        font-size: 14px;
        line-height: 22px;
      }

      @include vp--639 {
        font-size: 13px;
        line-height: 20px;
      }
    }
  }

  .orders-basket-bonus {
    padding: 15px 0;
    margin-bottom: 15px;
    border-top: 1px solid $color-whisper;
    border-bottom: 1px solid $color-whisper;

    .orders-basket-bonus-title {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: $color-dimgray;
      margin-bottom: 10px;

      @include vp--639 {
        font-size: 13px;
        line-height: 18px;
      }
    }

    .orders-basket-bonus-btns {
      display: flex;
      justify-content: space-between;

      .orders-basket-bonus-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 46px;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: $color-black;
        border: 2px solid $color-whisper;
        border-radius: 2px;
        background: transparent;
        outline: none;
        cursor: pointer;
        transition: 0.3s;

        @include vp--959 {
          font-size: 14px;
        }

        &:not(:last-child) {
          margin-right: 10px;
        }

        &:hover {
          border: 2px solid $color-very-light-grey;
          transition: 0.3s;
        }

        &.active {
          border: 2px solid $color-forest-green;
        }
      }
    }
  }

  .orders-basket-btns {
    .button {
      width: 100%;
      margin-bottom: 10px;

      @include vp--959 {
        height: 46px;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  .orders-basket-agreement {
    max-width: 260px;
    margin: 0 auto;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $color-nobel;
    text-align: center;

    @include vp--959 {
      max-width: unset;
    }

    @include vp--639 {
      max-width: 240px;
    }
  }
}

.favorites-order-empty {
  .favorites-logo {
    margin-bottom: 110px;

    @include vp--959 {
      margin: 0 auto;
      margin-top: 42px;
      margin-bottom: 140px;
    }

    @include vp--639 {
      margin-top: 37px;
      margin-bottom: 47px;
    }
  }

  .favorites-title {
    @include vp--639 {
      margin-bottom: 50px;
    }
  }


  .button {
    @include vp--639 {
      width: 100%;
      font-size: 14px;
      line-height: 22px;
    }
  }
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
  visibility: hidden;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: $color-black;
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 100%;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
  content: '';
  width: 8px;
  height: 8px;
  background: rgba(0, 0, 0, 0.9);
  position: absolute;
  top: 6px;
  left: 6px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:checked+.single-radio-label:before,
[type="radio"]:not(:checked)+.single-radio-label:before {
  background: transparent;
  border: 2px solid rgba(35, 120, 4, 0.2);
}

[type="radio"]:checked+.single-radio-label:before {
  border: 2px solid #237804;
}

[type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.radio-label {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
  margin-right: 50px;
  margin-top: 5px;
  margin-bottom: 10px;

  &:last-of-type {
    margin-right: 0;
  }

  @include vp--959 {
    font-size: 14px;
  }

  @include vp--639 {
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  span {
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #696969;
  
    @include vp--959 {
      font-size: 13px;
      line-height: 18px;
    }
  }

  &.single-radio-label {
    width: 100%;

    span {
      margin-top: 5px;

      &:first-child {
        margin-top: 10px;
      }
    }
  }
}

.acc-delivery-title {
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  color: $color-default-black;
  margin-bottom: 20px;

  @include vp--959 {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 15px;
  }

  @include vp--639 {
    font-size: 18px;
    line-height: 24px;
  }
}

.acc-delivery-subtitle {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $color-night-rider;
  margin-bottom: 20px;

  @include vp--959 {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 15px;
  }
}

[type="date"] {
  width: 100%;
  max-width: 316px;
  outline: none;
  border: 1px solid rgba(10, 10, 10, .1);
  padding: 8px 15px;  
}

[type="date"]::-webkit-calendar-picker-indicator {
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: $color-black;
}