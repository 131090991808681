.services {
  .services__list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 20px);
    margin: 0;
    padding: 50px 0 30px;
    list-style: none;
  
    @include vp--959 {
      padding: 50px 0 30px;
    }
  
    @include vp--639 {
      padding: 41px 0 30px;
    }
  
    &:after {
      position: absolute;
      bottom: 0;
      content: "";
      width: calc(100% - 20px);
      height: 1px;
      background-color: $color-whisper;
  
      @include vp--959 {
        width: calc(100% - 20px);
      }    
    }
  }
  
  .services__item {
    display: flex;
    width: calc(50% - 20px);
    margin-right: 20px;
    margin-bottom: 20px;
  
    @include vp--639 {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  &.last-child {
    .services__list {
      &:after {
        display: none;
      }
    }    
  }
}
