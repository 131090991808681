.link {
  color: $color-black;
  transition: color $trans--300, opacity $trans--300;

  display: flex;
  align-items: center;

  @include vp--959 {
    font-size: 14px;
    line-height: 20px;
  }

  &:hover,
  &:focus {
    color: $color-dimgray;
  }

  &:active {
    color: $color-night-rider;
  }
}

.link--white {
  color: $color-default-white;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;

  &:hover,
  &:focus {
    // спросить???
    color: $color-default-white;
    opacity: 0.8;
  }

  &:active {
    color: $color-default-white;
    opacity: 0.6;
  }
}

.link--green {
  &:hover,
  &:focus {
    color: $color-forest-green-2;
  }

  &:active {
    color: $color-forest-green;
  }
}
