.subscription {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  min-height: 200px;

  @include vp--1279 {
    min-height: 283px;
  }

  @include vp--959 {
    min-height: 303px;
  }
}

.subscription__wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 18px;

  &--is-success-submit {
    padding-bottom: 86px;

    @include vp--1279 {
      padding-bottom: 53px;
    }

    @include vp--959 {
      padding-bottom: 63px;
    }

    .subscription__wrapper-form {
      display: none;
    }

    .subscription__success-submit {
      display: block;
    }
  }

  @include vp--1279 {
    flex-direction: column;
    align-items: center;
    justify-content: start;
    max-width: 689px;
    margin: 0 auto;
  }

  @include vp--959 {
    max-width: 581px;
    padding-top: 40px;
  }

  @include vp--639 {
    padding-top: 35px;
    padding-bottom: 17px;
  }
}

.subscription__success-submit {
  display: none;
}

.subscription__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: min-content;
  margin: 0;
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;

  svg {
    flex-shrink: 0;
    margin-right: 20px;

    @include vp--1279 {
      margin-right: 0;
    }
    @include vp--959 {
      margin-right: 0;
      margin-bottom: 14px
    }
  }

  @include vp--1279 {
    justify-content: center;
    padding: 0 0 30px;
    text-align: center;
    max-width: 395px;
  }

  @include vp--959 {
    flex-direction: column;
    font-size: 20px;
    line-height: 28px;
  }

  @include vp--639 {
    padding: 0 0 30px;
  }
}

.subscription__wrapper-form {
  max-width: min-content;
  position: relative;
  padding-top: 5px;

  @include vp--1279 {
    padding-top: 0;
  }

  @include vp--959 {
    max-width: 100%;
  }
}

.subscription__success-submit {
  width: 72%;

  @include vp--1279 {
    width: 100%;
    text-align: center;
  }
}

.subscription form {
  display: flex;
  align-items: start;

  @include vp--1279 {
    justify-content: center;
  }


  @include vp--639 {
    flex-direction: column;
    align-items: stretch;
  }
}

.subscription form input {
  width: 556px;
  margin-right: 10px;
  background-color: $color-default-white;


  &:focus {
    outline: none;
    border-color: $color-forest-green-2;
  }

  @include vp--1279 {
    width: 552px;
  }

  @include vp--959 {
    width: 435px;
  }

  @include vp--639 {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
    text-align: center;
  }
}

.subscription__error {
  display: none;
  position: relative;
  padding-top: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: $color-harley-davidson-orange;

  @include vp--1279 {
    font-size: 14px;
    line-height: 20px;
    text-align: start;
  }

  @include vp--959 {
    font-size: 13px;
    line-height: 18px;
  }

  @include vp--639 {
    position: absolute;
    top: 125px;
    left: 0;
    width: 100%;
    padding: 7px 30px;
    text-align: center;
    background-color: $color-harley-davidson-orange;
    color: $color-default-white;
    font-size: 12px;
    line-height: 16px;
  }
}

.is-invalid {
  input {
    border-color: $color-harley-davidson-orange;
  }

  .subscription__error {
    display: block;
  }
}

.subscription__error-button {
  display: none;

  @include vp--639 {
    display: block;
    position: absolute;
    width: 9px;
    height: 9px;
    padding: 0;
    top: 6px;
    right: 6px;
    color: $color-default-white;
    background-color: transparent;
    border: none;

    svg {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.subscription__personal-data {
  margin: 0;
  padding-top: 9px;
  padding-right: 25px;
  font-size: 13px;
  line-height: 20px;
  color: $color-nobel;

  @include vp--1279 {
    max-width: 90%;
    margin: 0 auto;
    padding-right: 0;
    text-align: center;
  }

  @include vp--959 {
    max-width: 100%;
    padding-top: 11px;
    font-size: 12px;
    line-height: 18px;
  }

  @include vp--639 {
    padding-top: 8px;
    font-size: 10px;
    line-height: 13px;
  }
}

.subscription__success-submit {
  @include vp--1279 {
    width: auto;
    padding-top: 25px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  @include vp--959 {
    padding-top: 15px;
  }

  @include vp--639 {
    padding-top: 20px;
  }
}

.subscription__success-submit-title {
  margin: 0;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;

  @include vp--959 {
    font-size: 20px;
    line-height: 26px;
  }

  @include vp--639 {
    font-size: 18px;
    line-height: 24px;
  }
}

.subscription__success-submit-description {
  margin: 0;
  padding-top: 7px;
  font-size: 16px;
  line-height: 26px;

  @include vp--959 {
    font-size: 14px;
    line-height: 24px;
  }

  @include vp--639 {
    font-size: 13px;
    line-height: 21px;
  }
}
