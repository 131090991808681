.catalog-page {
  padding-bottom: 100px;

  @include vp--959 {
    padding-bottom: 80px;
  }

  @include vp--639 {
    padding-bottom: 40px;
  }

  .catalog-page-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -50px;

    @include vp--1023 {
      margin: 0 -15px;
    }

    @include vp--959 {
      margin: 0 -10px;
    }

    @include vp--639 {
      margin: 0 -15px;
    }

    .catalog-page-item {
      width: 100%;
      max-width: 220px;
      display: flex;
      flex-direction: column;
      margin: 0 50px;
      margin-bottom: 60px;

      @include vp--1023 {
        max-width: 200px;
        margin: 0 15px;
        margin-bottom: 50px;
      }

      @include vp--959 {
        max-width: 180px;
        margin: 0 10px;
        margin-bottom: 30px;
      }

      @include vp--639 {
        max-width: 125px;
        margin: 0 15px;
        margin-bottom: 30px;
      }

      .img {
        width: 124px;
        height: 124px;
        margin-bottom: 10px;

        @include vp--959 {
          width: 110px;
          height: 110px;
          margin-bottom: 5px;
        }

        @include vp--639 {
          width: 90px;
          height: 90px;
        }
      }

      .title {
        font-weight: 600;
        margin-bottom: 10px;
        transition: color 0.3s;

        &:hover {
          color: #696969;
          transition: color 0.3s;
        }

        @include vp--959 {
          font-size: 14px;
          line-height: 22px;
          margin-bottom: 5px;
        }

        @include vp--639 {
          font-size: 13px;
          line-height: 18px;
        }
      }

      .list {
        .list-item {
          margin-bottom: 10px;

          @include vp--959 {
            margin-bottom: 5px;
          }

          a {
            line-height: 26px;
            color: #343434;
            transition: color 0.3s;           
  
            &:last-child {
              margin-bottom: 0;
            }

            &:hover {
              color: #696969;
              transition: color 0.3s;
            }

            @include vp--959 {
              font-size: 14px;
              line-height: 22px;
            }
  
            @include vp--639 {
              font-size: 13px;
              line-height: 18px;
            }
          }          
        }
      }
    }
  }
}