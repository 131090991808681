.profile-tooltip {
  position: absolute;
  display: none;
  width: 201px;
  padding: 15px;
  right: 0;
  top: calc(100% + 20px);
  z-index: 200;

  border-radius: 2px;
  box-shadow: 0 10px 40px 20px rgba(0, 0, 0, 0.05);
  background-color: $color-default-white;

  li {
    margin-right: 0;
    width: 100%;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  li:not(:last-child) {
    border-bottom: 1px solid $color-white-smoke-2;
  }

  .link {
    flex-direction: row-reverse;
    color: $color-nero;
  }

  svg {
    margin-right: 10px;
  }

  &__count {
    line-height: 24px;
    font-weight: 600;
    margin-left: auto;
    color: $color-forest-green;
    order: -1;
  }

  .header__acc.active ~ & {
    display: block;

    @include vp--639 {
      display: none;
    }
  }
}
