.advice-card {
  display: flex;
  flex-direction: column;
  transition: color $trans--300;

  &--main {
    width: 100%;
    max-width: 590px;
    margin-right: 20px;

    @include vp--1279 {
      min-width: 383px;
    }

    @include vp--959 {
      min-width: 280px;
    }

    @include vp--639 {
      min-width: unset;
      margin-bottom: 30px;
    }

    picture {
      height:  297px;

      @include vp--959 {
        height: 300px;
      }

      @include vp--639 {
        height: 196px;
      }
    }

    img {
      object-fit: cover;
      height: 100%;
      transition: filter $trans--300;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover .advice-card__title-main,
      &:focus .advice-card__title-main {
        color: $color-dimgray;
      }

      &:active .advice-card__title-main {
        color: $color-night-rider;
      }

      &:hover img,
      &:focus img {
        filter: brightness(70%);
      }
    }
  }

  &--width {
    img {
      object-fit: cover;
      height: 100%;
      transition: filter $trans--300;
    }

    picture {
      height: 198px;
    }

    @include vp--959 {
      padding-bottom: 19px;
      border-bottom: 1px solid rgba($color: $color-black, $alpha: 0.05);

      picture,
      img {
        display: none;
      }
    }

    @include vp--639 {
      width: 100%;
      border-top: 1px solid rgba($color: $color-black, $alpha: 0.05);
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover img,
      &:focus img {
        filter: brightness(70%);
      }
    }
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover .advice-card__title,
    &:focus .advice-card__title {
      color: $color-dimgray;
    }

    &:active .advice-card__title {
      color: $color-night-rider;
    }
  }
}

.advice-card__info {
  font-size: 13px;
  line-height: 18px;
  color: $color-dimgray;

  @include vp--959 {
    font-size: 12px;
    line-height: 16px;
  }

  span {
    position: relative;
    display: inline-block;
    padding-left: 15px;

    &:before {
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      left: 6px;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: $color-dimgray;
    }
  }
}

.advice-card__title {
  position: relative;
  margin: 0;
  margin-bottom: 17px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: color $trans--300;

  @include vp--1279 {
    -webkit-line-clamp: 3;
  }

  @include vp--959 {
    margin-bottom: 11px;
    font-size: 14px;
  }

  @include vp--639 {
    -webkit-line-clamp: 2;
  }

  .advice-card--main & {
    margin-bottom: 15px;
    font-size: 22px;
    line-height: 32px;

    @include vp--1279 {
      font-size: 20px;
      -webkit-line-clamp: 2;
    }

    @include vp--959 {
      margin-bottom: 11px;
      font-weight: 700;
      line-height: 22px;
    }

    @include vp--639 {
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.advice-card__label {
  margin-top: 9px;
  margin-bottom: 10px;
  margin-right: auto;
  padding: 3px 12px;
  font-size: 14px;
  line-height: 20px;
  color: $color-default-white;
  font-weight: 500;

  @include vp--959 {
    font-size: 12px;
    line-height: 16px;
    padding: 3px 10px;
  }

  &--color-pumpkin {
    background-color: $color-pumpkin;
  }

  &--color-perfume {
    background-color: $color-perfume;
  }

  &--color-cardinal {
    background-color: $color-cardinal;
  }

  &--color-blue-chill {
    background-color: $color-blue-chill;
  }
}
