.promo-block-card {
  position: relative;
  display: block;
  width: 100%;
  min-height: 240px;
  padding: 22px;
  border-radius: 2px;
  color: $color-default-white;

  &:hover{
    background-color: rgba(255, 255, 255, 0.2);
    transition: $trans--300;
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.4);
    transition: $trans--300;
  }

  img {
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 2px;

    @include vp--1279 {
      border-radius: 4px;
    }

    @include vp--959 {
      border-radius: 2px;
      object-position: 60% 40%;
    }

  }

  @include vp--1279 {
    min-height: 180px;
    padding: 15px;
    border-radius: 4px;
  }

  @include vp--959 {
    min-height: 210px;
    padding: 20px;
    border-radius: 2px;
  }

  @include vp--639 {
    min-height: 150px;
    padding: 15px;
  }

}
.promo-block-card__title {
  display: block;
  max-width: 160px;
  margin: 0;
  font-size: 15px;
  line-height: 22px;
  font-weight: 600;

  @include vp--959 {
    font-size: 14px;
  }

  @include vp--639 {
    font-size: 14px;
    line-height: 20px;
  }
}
