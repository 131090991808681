.main-nav {
  &__toggle {
    display: none;
  }

  &__list {
    display: flex;
  }

  &__item {
    margin-right: 20px;
    @include vp--959 {
      display: none;
    }
  }

  &__item--tel {
    display: none;

    @include vp--959 {
      display: block;
    }
  }
}
