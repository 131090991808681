.advice-block {
  position: relative;
  margin-top: 30px;
  padding-bottom: 55px;

  @include vp--959 {
    margin-top: 20px;
    padding-bottom: 21px;
  }

  &:after {
    position: absolute;
    bottom: 0;
    content: "";
    width: calc(100% - 60px);
    height: 1px;
    background-color: $color-whisper;

    @include vp--959 {
      width: calc(100% - 60px);
    }

    @include vp--639 {
      width: calc(100% - 40px);
    }
  }
}

.advice-block__link-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 30px 0;
  padding: 0;
  list-style: none;

  @include vp--959 {
    gap: 10px;
    row-gap: 11px;
    margin: 20px 0 21px;
  }

  @include vp--639 {
    margin-bottom: 32px;
  }
}

.advice-block__wrap {
  display: flex;

  @include vp--639 {
    flex-direction: column;
  }
}

.advice-block__container {
  display: grid;
  grid-column-gap: 31px;
  grid-template-columns: 220px auto;

  @include vp--1279 {
    grid-column-gap: 20px;
  }

  @include vp--959 {
    grid-gap: 0;
    grid-template-columns: auto;

    & > .advice-card--width:first-child {
      margin-top: -10px;
    }
  }

  &--articles {
    display: flex;
    flex-direction: column;
    padding-top: 4px;
    border-top: 1px solid rgba($color: $color-black, $alpha: 0.05);

    @include vp--1279 {
      min-width: 267px;
      padding-top: 0;
      border-top: none;
    }

    @include vp--959 {
      max-width: unset;
    }

    @include vp--639 {
      margin-left: unset;
    }

    .advice-card {
      padding-bottom: 22px;
      border-bottom: 1px solid rgba($color: $color-black, $alpha: 0.05);

      @include vp--1279 {
        &:first-child {
          margin-top: -10px;
        }

        &:last-child {
          border-bottom: none;
        }
      }

      @include vp--959 {
        padding-bottom: 19px;

        &:first-child {
          margin-top: 0;
        }
      }

      @include vp--639 {
        margin-top: 0;
      }
    }
  }
}
