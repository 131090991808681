.advantage-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 180px;
  padding: 19px 20px 15px;
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 16px;
  color: $color-default-white;
  border-radius: 2px;

  &:hover{
    background-color: rgba(0, 0, 0, 0.3);
    transition: $trans--300;
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.4);
    transition: $trans--300;
  }

  img {
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 2px;

    @include vp--959 {
      object-position: 60% 40%;
    }
  }

  @include vp--959 {
    min-height: 160px;
    padding: 23px 20px 15px;
  }

  @include vp--639 {
    min-height: 150px;
    padding: 20px 20px 15px;
  }
}

.advantage-card__wrapper {
  max-width: 245px;
  margin-bottom: 40px;

  @include vp--1279 {
    margin-bottom: 43px;
  }

  @include vp--959 {
    margin-bottom: 23px;
  }

}

.advantage-card__title {
  margin: 0 0 5px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;

  @include vp--1279 {
    font-size: 14px;
    line-height: 22px;
  }

  @include vp--639 {
    font-size: 13px;
    line-height: 20px;
  }
}

.advantage-card__description {
  margin: 0;
  line-height: 26px;

  @include vp--1279 {
    font-size: 16px;
    line-height: 26px;
  }

  @include vp--959 {
    font-size: 14px;
    line-height: 24px;
  }

  @include vp--639 {
    font-size: 13px;
    line-height: 21px;}
}
