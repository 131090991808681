.callback-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;
  border-radius: 5px;

  @include vp--959 {
    width: 440px;
    padding: 40px 30px 30px;
  }

  @include vp--767 {
    width: 440px;
    padding: 40px 30px 30px;
  }

  @include vp--639 {
    width: 100%;
    border-radius: 0;
    padding: 40px 20px 30px;
  }
}

.callback-form__title {
  margin: 0;
  margin-bottom: 30px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 700;

  @include vp--959 {
    font-size: 20px;
    line-height: 26px;
  }

  @include vp--639 {
    margin-bottom: 60px;
    font-size: 18px;
    line-height: 24px;
  }
}

.callback-form__form {
  width: 100%;

  .custom-input {
    margin-bottom: 10px;

    input {
      width: 100%;
      height: 54px;
      font-size: 16px;
      line-height: 26px;
      color: $color-black;
      font-weight: 400;
      border: 1px solid $color-whisper;
      border-radius: 2px;

      @include vp--959 {
        height: 46px;
        padding-top: 5px;
        font-size: 14px;
        line-height: 24px;
      }

      &::placeholder {
        color: $color-dimgray;
      }

      &:focus,
      &:hover {
        background-color: $color-transparent;
        border-color: $color-very-light-grey;
      }
    }

    &.is-invalid input {
      border: 1px solid $color-harley-davidson-orange;
    }
  }

  .custom-select {
    position: unset;
    width: 100%;
    padding: 0;

    &__error .custom-select__button {
      border: 1px solid $color-harley-davidson-orange;
    }
  }

  .custom-select__button {
    height: 54px;
    margin-bottom: 10px;
    padding: 8px 15px;
    padding-right: 20px;
    border: 1px solid $color-whisper;
    border-radius: 2px;

    @include vp--959 {
      height: 46px;
      margin-bottom: 9px;
      font-size: 14px;
      line-height: 24px;
    }

    @include vp--639 {
      margin-bottom: 10px;
    }

    &:focus,
      &:hover {
        border-color: $color-very-light-grey;
      }
  }

  .custom-select__placeholder {
    top: 59.9%;
    left: 54px;
    font-size: 16px;
    line-height: 26px;
    color: $color-dimgray;

    @include vp--959 {
      top: 61.5%;
      left: 45px;
      font-size: 14px;
      line-height: 24px;
    }

    @include vp--639 {
      top: 203px;
      left: 35px;
    }
  }

  .button {
    width: 100%;

    @include vp--959 {
      height: 46px;
    }
  }

  .custom-select__icon {
    width: 8px;
    height: 5px;
    background-color: $color-black;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    transform: rotate(180deg);
    transition: transform $trans--300;
    will-change: transform;
  }

  .custom-select.is-open .custom-select__icon {
    transform: rotate(0);
  }

  .custom-select__list {
    bottom: 91px;
    width: calc(100% - 80px);
    max-height: 266px;
    border: none;
    border-radius: 2px;
    box-shadow: 0 4px 10px 0 rgba($color-default-black, 0.05);

    @include vp--959 {
      width: calc(100% - 60px);
      bottom: 76px;
    }

    @include vp--639 {
      top: -30px;
      bottom: unset;
      width: calc(100% - 40px);
    }
  }

  .custom-select__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 10px);
    margin: 0 5px 0 6px;
    padding: 13px 9px 9px 9px;
    font-size: 16px;
    line-height: 26px;
    color: $color-black;
    border-top: 1px solid $color-whisper;

    @include vp--959 {
      font-size: 14px;
      line-height: 24px;
    }

    &:first-child {
      border: none;
    }

    &[aria-selected='true'] {
      border: none;

      & + .custom-select__item {
        border: none;
      }
    }
  }
}

.custom-select__text,
.custom-select__text a {
  color: $color-black;

  @include vp--959 {
    font-size: 14px;
    line-height: 24px;
  }
}



.callback-form__close-btn {
  top: 20px;
  right: 20px;
  color: $color-black;

  @include vp--959 {
    top: 25px;
    right: 25px;
  }
}
