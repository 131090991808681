.slider-wrapper {
  width: 268px;
  margin-bottom: 30px;

  .slider {
    display: block;
    position: relative;
    height: 20px;
    width: 268px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select:none;
    -o-user-select:none;
    user-select:none;
    margin-left: -5px;
  
    .slider-touch-left, .slider-touch-right {
      display: block;
      position: absolute;
      height: 36px;
      width: 36px;
      padding: 6px;
      z-index: 2;
      cursor: pointer;
      
      span {
        display: block;
        width: 100%;
        height: 100%;
        background: $color-default-white;
        border: 2px solid $color-forest-green;
        border-radius: 50%;
      }
    }
  
    .slider-line {
      position: absolute;
      width: 256px;
      left: 7px;
      top: 16px;
      height: 4px;
      border-radius: 4px;
      background: $color-whisper;
      z-index: 0;
      overflow: hidden;
  
      span {
        display: block;
        height: 100%;
        width: 0%;
        background: $color-forest-green;
      }
    }
  }
}

#result {
  display: none;
}