.promotions-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 5px;

  @include vp--1279 {
    margin-top: 35px;
  }

  @include vp--639 {
    margin-top: 30px;
    margin-bottom: 0;
  }

  .button--arrows {
    @include vp--959 {
      background: transparent;
    }
  }
}

.promotions-title__title {
  margin: 0;
  margin-right: 38px;
  font-size: 26px;
  line-height: 24px;
  font-weight: 700;
  color: $color-black;

  @include vp--1279 {
    margin-right: 32px;
    font-size: 22px;
    line-height: 28px;
  }

  @include vp--959 {
    margin-right: auto;
    font-size: 24px;
    line-height: 34px;
  }

  @include vp--639 {
    font-size: 20px;
    line-height: 28px;
  }
}

.promotions-text {
  font-size: 18px;
  line-height: 30px;
  color: $color-default-black;

  @include vp--959 {
    font-size: 16px;
    line-height: 28px;
  }

  @include vp--639 {
    font-size: 14px;
    line-height: 24px;
  }
}
