.nav-footer {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0 53px;

  @include vp--1279 {
    padding: 15px 0 21px;
  }

  @include vp--959 {
    position: relative;
    padding: 10px 0 26px;
  }

  @include vp--639 {
    padding: 0 0 8px;
  }
}

.nav-footer__item {
  padding: 35px 0;

  &:nth-child(1n+3) {
    @include vp--959  {
      padding: 35px 0 0;
    }
    @include vp--639 {
      padding: 15px 0;
    }
  }

  &--contacts {
    flex-basis: 34%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    @include vp--1279 {
      flex-basis: 51%;
      padding-top: 25px;
    }
  }

  &--company {
    flex-basis: 22%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    @include vp--1279 {
      flex-basis: 21%;
      order: 5;
      padding-right: 40px;
      padding-top: 40px;
      border-bottom: none;
    }

    @include vp--959 {
      order: 8;
      padding-right: 0;
    }
  }

  &--buyers {
    flex-basis: 44%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);


    @include vp--1279 {
      flex-basis: 42%;
      padding-top: 40px;
      order: 6;
      align-content: space-between;
      border-bottom: none;
    }

    @include vp--959 {
      order: 7;
    }
  }

  &--catalog {
    flex-basis: 34%;

    @include vp--1279 {
      flex-basis: 30%;
      order: 4;
      padding-right: 40px;
      padding-top: 40px;
    }

    @include vp--959 {
      order: 5;
      padding-right: 0;
    }
  }

  &--services {
    flex-basis: 44%;

    @include vp--1279 {
      flex-basis: 48%;
      padding-left: 0;
      padding-top: 25px;
      order: 3;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    @include vp--959 {
      order: 4;
      padding-right: 0;
      border-bottom: none;
    }
  }

  &--payment-methods {

    flex-basis: 22%;
    padding-left: 10px;

    @include vp--1279 {
      order: 7;
      padding-top: 15px;
      padding-bottom: 10px;
    }

    @include vp--959 {
      order: 3;
      flex-basis: auto;
      position: absolute;
      top: 9px;
      right: 32px;
    }

  }
  @include vp--959 {
    padding: 22px 0;

    &:nth-child(1n+2) {
      flex-basis: 100%;
    }
  }

  @include vp--639 {
    margin-bottom: 0;

    &:not(&:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    &:nth-child(1n+3):not(&--company):not(&--catalog), &:nth-child(3), &:nth-child(5) {
      max-height: max-content;
    }

    &--payment-methods {
      order: 9;
      position: relative;
      top: 0;
    }
  }

}

.nav-footer__title {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;

  svg {
    display: none;

    @include vp--639 {
      display: block;
    }
  }

  @include vp--1279 {
    font-size: 14px;
  }

  @include vp--639 {
    padding-right: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.nav-footer__link-list {
  margin: 10px 0 0;
  padding: 0;
  list-style: none;

  &:not(&--company) {
    column-count: 2;

    @include vp--959 {
      column-count: 3;
    }

    @include vp--639 {
      column-count: 1;
    }
  }

  &--buyers,  &--services  {
    @include vp--1279 {
      .nav-footer__link-wrapper {
        min-width: 208px;
      }
    }
    @include vp--959 {
      .nav-footer__link-wrapper {
        min-width: auto;
      }
    }
  }

  @include vp--959 {
    margin: 8px 0 0;

    &--company {
      column-count: 3;
    }
  }

  @include vp--639 {
    max-height: 0;
    margin: 0;
    position: relative;
    z-index: 10;
    overflow: hidden;
    transition: max-height $trans--300;

    &--company {
      column-count: 1;
    }
  }
}

.nav-footer__link-wrapper {
  min-height: 26px;

  &:not(&:last-child) {
    margin-bottom: 6px;

    @include vp--1279 {
      margin-bottom: 10px;
    }

    @include vp--959{
      margin-bottom: 5px;
    }

    @include vp--639 {
      margin-bottom: 6px;
    }
  }

  @include vp--1279 {
    min-height: 20px;
  }
}

.nav-footer__link {
  line-height: 30px;
  color: rgba(255, 255, 255, 0.8);

  @media (hover: hover), screen and (min-width: 0\0) {

    &:hover {
      opacity: 0.8;
      transition: $trans--300;
    }
  }

  &:active {
    opacity: 0.6;
    transition: $trans--300;
  }

  @include vp--1279 {
    font-size: 14px;
    line-height: 20px;
  }

  @include vp--959 {
    font-size: 13px;
  }

  @include vp--639 {
    font-size: 12px;
    line-height: 18px;
  }
}

.nav-footer__item--active {

    .nav-footer__link-list {
      @include vp--639 {
        max-height: 100%;
        margin-top: 8px;
    }
  }

  .nav-footer__title svg {
    transform: rotateX(180deg);
  }
}
