.services-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 300px;
  padding: 76px 30px 0;
  color: $color-default-white;
  border-radius: 4px;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: -1;
    opacity: 60%;
    border-radius: 4px;
    background: linear-gradient(
        to right,  #000000 0%,
      rgba(0, 0, 0, 0.9914) 6.67%,
      rgba(0, 0, 0, 0.9645) 13.33%,
      rgba(0, 0, 0, 0.9183) 20%,
      rgba(0, 0, 0, 0.8526) 26.67%,
      rgba(0, 0, 0, 0.7682) 33.33%,
      rgba(0, 0, 0, 0.6681) 40%,
      rgba(0, 0, 0, 0.5573) 46.67%,
      rgba(0, 0, 0, 0.4427) 53.33%,
      rgba(0, 0, 0, 0.3319) 60%,
      rgba(0, 0, 0, 0.2318) 66.67%,
      rgba(0, 0, 0, 0.1474) 73.33%,
      rgba(0, 0, 0, 0.0817) 80%,
      rgba(0, 0, 0, 0.0355) 86.67%,
      rgba(0, 0, 0, 0.0086) 93.33%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  img {
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    border-radius: 4px;
  }

  @include vp--1279 {
    min-height: 280px;
    padding: 57px 30px 35px;
  }

  @include vp--959 {
    padding: 43px 20px 35px;
  }

  @include vp--639 {
    padding: 53px 20px 35px;
  }
}

.services-card__title {
  margin: 0 0 6px;
  font-size: 34px;
  font-weight: 700;
  line-height: 40px;

  @include vp--1279 {
    font-size: 28px;
    line-height: 34px;
  }

  @include vp--959 {
    font-size: 22px;
    line-height: 30px;
  }

  @include vp--639 {
    font-size: 20px;
    line-height: 28px;
  }
}

.services-card__description {
  max-width: 332px;
  margin: 0 0 20px;
  font-size: 16px;
  line-height: 26px;


  @include vp--959 {
    max-width: 222px;
    font-size: 14px;
    line-height: 24px;
  }

  @include vp--639 {
    max-width: 205px;
    margin-bottom: 26px;
    font-size: 13px;
    line-height: 21px;
  }
}

