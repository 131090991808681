.article-top {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #0A0A0A;
  margin-bottom: 100px;

  @include vp--1023 {
    margin-bottom: 60px;
  }

  @include vp--767 {
    margin-bottom: 30px;
    font-size: 13px;
    line-height: 18px;
  }

  @include vp--639 {
    font-size: 12px;
    line-height: 16px;
  }

  .article-top-item {
    display: flex;
    align-items: center;
    margin-right: 20px;

    @include vp--639 {
      margin-right: 18px;
      margin-bottom: 10px;
    }

    span {
      margin-left: 5px;
    }
  }

  .article-top-code {
    font-weight: 400;
    color: #696969;
    margin-right: 20px;
  }
}

.article-flex {
  display: flex;
  justify-content: space-between;

  @include vp--639 {
    flex-direction: column;
    align-items: center;
  }
}

.article-card {
  display: flex;
  padding-bottom: 50px;

  &.mobile-on {
    display: none;
  }

  @include vp--959 {
    width: 320px;
    flex-direction: column;
    padding-bottom: 0;
    align-items: center;

    &.mobile-on {
      width: 100%;
      display: block;
      margin-top: 30px;
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 1px solid $color-whisper;

      .article-description {
        width: 100%;
      }
    }
  }

  @include vp--639 {
    width: 100%;
    max-width: 320px;
    padding-bottom: 20px;
    margin-bottom: 25px;
    border-bottom: 1px solid $color-whisper;
  }

  .article-gallery {
    width: 364px;
    margin-right: 70px;

    @include vp--1279 {
      width: 195px;
      margin-right: 25px;
    }

    @include vp--959 {
      width: 100%;
      margin-right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .article-gallery-top {
      .main-img {
        width: 364px;
        height: 364px;
        margin-bottom: 30px;

        @include vp--1279 {
          width: 195px;
          height: 195px;
          margin-bottom: 20px;
        }

        @include vp--639 {
          margin-bottom: 15px;
        }
      }      
    }

    .article-gallery-bottom {
      display: flex;
      flex-wrap: wrap;

      @include vp--959 {
        margin: 0 -5px;
      }

      .select-img {
        width: 60px;
        height: 60px;
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: pointer;

        @include vp--1279 {
          width: 54px;
          height: 54px;
        }

        @include vp--959 {
          margin-left: 5px;
          margin-right: 5px;
        }

        @include vp--639 {
          width: 48px;
          height: 48px;
        }

        &.active {
          opacity: 0.5;
        }
      }
    }
  }

  .article-description {
    width: 395px;
    padding-top: 15px;

    @include vp--1023 {
      width: 350px;
    }

    @include vp--959 {
      width: 100%;
    }

    @include vp--639 {
      padding-top: 0;
    }

    .article-label {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $color-black;
      margin-bottom: 5px;

      @include vp--959 {
        font-size: 13px;
        line-height: 18px;
      }

      @include vp--639 {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .article-radio-btns {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 30px;
      }

      .article-radio-btn {
        width: 125px;
        min-height: 64px;
        border: 1px solid $color-whisper;
        border-radius: 2px;
        padding: 10px;
        cursor: pointer;
        transition: 0.3s;

        @include vp--1023 {
          width: 110px;
        }

        @include vp--959 {
          width: 100px;
          min-height: 55px;
        }

        &:hover {
          border: 1px solid $color-very-light-grey;
          transition: 0.3s;
        }

        &.big {
          min-height: 100px;
        }

        &.active {
          border: 2px solid $color-forest-green;
        }
        
        &:not(:last-child) {
          margin-right: 10px;

          @include vp--959 {
            margin-right: 5px;
          }
        }

        .article-radio-title {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: $color-black;
          margin-bottom: 5px;

          @include vp--959 {
            font-size: 13px;
            line-height: 18px;
            margin-bottom: 3px;
          }

          @include vp--639 {
            font-size: 12px;
            line-height: 16px;
          }
        }

        .article-radio-text {
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          color: $color-black;
          opacity: 0.5;
          margin-bottom: 5px;

          &:last-of-type {
            margin-bottom: 0;
          }

          @include vp--959 {
            font-size: 11px;
            line-height: 15px;
          }

          @include vp--639 {
            font-size: 10px;
            line-height: 14px;
          }
        }

        .article-radio-subtext {
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          color: $color-black;

          @include vp--959 {
            font-size: 11px;
            line-height: 15px;
          }

          @include vp--639 {
            font-size: 10px;
            line-height: 14px;
          }
        }
      }
    }

    .article-about-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $color-black;
      margin-bottom: 10px;

      @include vp--959 {
        font-size: 14px;
        line-height: 22px;

        &.mobile-off {
          display: none;
        }
      }

      @include vp--639 {
        font-size: 13px;
        line-height: 20px;
      }
    }

    .article-about-text {
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: $color-black;
      margin-bottom: 15px;

      @include vp--959 {
        font-size: 14px;
        line-height: 22px;

        &.mobile-off {
          display: none;
        }
      }

      @include vp--639 {
        font-size: 13px;
        line-height: 20px;
      }
    }

    .article-about-img {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      @include vp--959 {
        &.mobile-off {
          display: none;
        }
      }

      img {
        width: 64px;
        height: 37px;
        margin-right: 10px;

        @include vp--639 {
          width: 60px;
          height: 34px;
        }
      }

      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: $color-black;

        @include vp--959 {
          font-size: 14px;
          line-height: 22px;
        }

        @include vp--639 {
          font-size: 13px;
          line-height: 18px;
        }
      }
    }

    .article-about-table {
      display: flex;
      flex-direction: column;

      @include vp--959 {
        &.mobile-off {
          display: none;
        }
      }

      .article-about-table-item {
        display: flex;
        align-items: baseline;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        .article-about-table-left {
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: $color-dimgray;
          margin-right: 10px;

          @include vp--959 {
            font-size: 13px;
            line-height: 18px;
          }

          @include vp--639 {
            font-size: 12px;
            line-height: 16px;
          }
        }

        .article-about-table-middle {
          flex: 1;
          border-bottom: 1px dashed $color-whisper;
        }

        .article-about-table-right {
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: $color-black;
          margin-left: 10px;

          @include vp--959 {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }
  }
}

.article-basket {
  width: 300px;
  height: max-content;

  @include vp--959 {
    width: 250px;

    .custom-input--bigger input {
      width: 116px;

      @include vp--639 {
        width: 100%;
      }
    }

    .button {
      height: 46px;
      font-size: 14px;
      line-height: 22px;
    }
  }

  @include vp--639 {
    width: 100%;
    max-width: 320px;
  }

  .article-basket-header {
    display: flex;
    justify-content: space-around;
    margin-bottom: 19px;

    @include vp--959 {
      margin-bottom: 10px;
    }

    .article-basket-header-item {
      display: flex;
      align-items: center;
      background: transparent;
      border: none;
      cursor: pointer;
      opacity: 1;
      padding: 0;

      &:hover {
        opacity: 0.8;
      }

      svg {
        margin-right: 10px;
        fill: $color-default-white;
      }

      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: $color-black;

        @include vp--959 {
          font-size: 14px;
        }

        @include vp--639 {
          font-size: 13px;
          line-height: 20px;
        }
      }
    }
  }

  .article-basket-inner {
    padding: 20px;
    border: 1px solid $color-whisper;
    border-radius: 2px;
    background: $color-default-white;

    @include vp--959 {
      padding: 15px;
    }

    .article-basket-oldprice {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: $color-black;
      text-decoration: line-through;
      margin-bottom: 5px;

      @include vp--959 {
        font-size: 13px;
        line-height: 18px;
      }
    }

    .article-basket-price {
      display: flex;
      align-items: baseline;
      margin-bottom: 5px;

      .article-basket-price-number {
        font-weight: 800;
        font-size: 34px;
        line-height: 40px;
        margin-right: 5px;
        color: $color-black;

        @include vp--959 {
          font-size: 28px;
          line-height: 34px;
        }
      }

      .article-basket-price-amount {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $color-black;
      }
    }

    .article-basket-amount {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 10px;
      color: $color-nobel;

      @include vp--959 {
        font-size: 13px;
        line-height: 18px;
      }
    }

    .article-basket-points {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .article-basket-points-text {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $color-black;

        @include vp--639 {
          font-size: 13px;
          line-height: 20px;
        }
      }

      .article-basket-points-number {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $color-black;

        @include vp--959 {
          font-size: 13px;
          line-height: 18px;
        }

        @include vp--639 {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }

    .card-popup__count {
      margin-top: 0;
      margin-bottom: 10px;
      border: 1px solid $color-whisper;
      border-radius: 2px;

      .button,
      input {
        background: transparent;
        color: $color-black;
      }
    }

    .article-basket-btns {
      display: flex;
      margin-bottom: 10px;

      .button--cart {
        height: 50px;
        flex: 1;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include vp--959 {
          height: 46px;
        }

        svg {
          margin-right: 10px;
        }
      }
    }

    .article-btn {
      width: 100%;
      margin-bottom: 30px;

      @include vp--959 {
        margin-bottom: 23px;
      }

      @include vp--639 {
        margin-bottom: 20px;
      }
    }

    .article-basket-stock {
      .article-basket-stock-header {
        padding-bottom: 12px;
        margin-bottom: 10px;
        border-bottom: 1px solid $color-whisper;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.out-of-stock {
          border-bottom: none;
        }

        .article-basket-stock-state {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: $color-night-rider;
          display: flex;
          align-items: center;

          svg {
            margin-right: 10px;
          }

          @include vp--639 {
            font-size: 13px;
            line-height: 18px;
          }
        }

        .article-basket-stock-number {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: $color-black;

          @include vp--639 {
            font-size: 13px;
          }
        }
      }

      .article-basket-stock-body {
        .article-basket-stock-item {
          
          &:not(:last-child) {
            margin-bottom: 8px;
          }

          .article-basket-stock-title {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: $color-black;
            margin-bottom: 5px;

            @include vp--959 {
              font-size: 13px;
              line-height: 18px;
            }

            @include vp--639 {
              font-size: 12px;
              line-height: 16px;
            }
          }

          .article-basket-stock-text {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: $color-gray;

            @include vp--959 {
              font-size: 13px;
              line-height: 18px;
            }

            @include vp--639 {
              font-size: 12px;
              line-height: 16px;
            }

            span {
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              color: $color-black;
              margin-left: 10px;

              @include vp--959 {
                font-size: 13px;
                line-height: 18px;
              }

              @include vp--639 {
                font-size: 12px;
                line-height: 16px;
              }
            }
          }
        }
      }
    }
  }
}

.tabs-wrapper {
  width: 100%;  
  padding-bottom: 50px;  
  border-bottom: 1px solid $color-whisper;

  @include vp--959 {
    padding-bottom: 40px;
  }

  .tabs-header {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $color-whisper;

    @include vp--639 {
      padding-bottom: 17px;
    }

    .tab {
      display: flex;
      align-items: center;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: $color-night-rider;
      cursor: pointer;
      position: relative;

      @include vp--959 {
        font-size: 14px;
        line-height: 24px;
      }

      @include vp--639 {
        font-size: 13px;
        line-height: 20px;
      }

      &:not(:last-child) {
        margin-right: 30px;

        @include vp--639 {
          margin-right: 15px;
        }
      }

      span {
        font-weight: 600;
        line-height: 20px;
        margin-left: 10px;

        @include vp--959 {
          margin-left: 7px;
        }
      }

      &.active {
        &::after {
          content: "";
          width: 100%;
          position: absolute;
          bottom: -20px;
          height: 3px;
          background: $color-forest-green;

          @include vp--639 {
            bottom: -17px;
          }
        }

        color: $color-black;;
      }
    }
  }

  .tabs-body {
    .tabs-body-item {
      display: none;

      &.active {
        display: block;
      }
    }
  }
}

.about-subject {
  iframe {
    width: 100%;
    height: 450px;
    border: none;
    outline: none;
    margin-bottom: 30px;

    @include vp--959 {
      margin-bottom: 20px;
      height: 314px;
    }

    @include vp--639 {
      margin-bottom: 10px;
      height: 170px;
    }
  }

  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: $color-black;
    margin: 0;
    padding: 0;

    @include vp--959 {
      font-size: 14px;
      line-height: 24px;
    }

    @include vp--639 {
      font-size: 12px;
      line-height: 16px;
    }

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }
}

.characteristics {
  padding-top: 20px;

  .characteristics-item {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .characteristics-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $color-black;
      margin-bottom: 10px;

      @include vp--639 {
        font-size: 13px;
        line-height: 18px;
      }
    }

    .characteristics-row {
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;

      .characteristics-row-left {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $color-dimgray;
        margin-bottom: 10px;

        @include vp--639 {
          font-size: 12px;
          line-height: 16px;
        }
      }

      .characteristics-row-middle {
        flex: 1;
        border-bottom: 1px dashed $color-whisper;
        margin: 0 10px;
      }

      .characteristics-row-right {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $color-black;
        margin-bottom: 10px;

        @include vp--639 {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}

.reviews {
  padding-top: 30px;

  .reviews-header {
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;
    color: $color-black;
    margin-bottom: 20px;

    @include vp--959 {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .reviews-btn {
    margin-bottom: 40px;

    @include vp--959 {
      font-size: 14px;
      line-height: 14px;
      height: 40px;
    }
  }

  .reviews-item {
    border-bottom: 1px solid $color-whisper;
    padding-bottom: 40px;
    margin-bottom: 40px;

    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 50px;
    }

    .reviews-item-title {
      font-weight: bold;
      font-size: 22px;
      line-height: 28px;
      color: $color-black;
      margin-bottom: 15px;

      @include vp--959 {
        font-size: 20px;
        line-height: 26px;
      }

      @include vp--639 {
        font-size: 18px;
        line-height: 24px;
      }
    }

    .reviews-item-like {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      svg {
        margin-right: 10px;
      }

      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: $color-black;

        @include vp--959 {
          font-size: 13px;
          line-height: 18px;
        }
      }
    }

    .reviews-item-text {
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: $color-black;
      margin-bottom: 10px;

      @include vp--959 {
        font-size: 14px;
        line-height: 24px;
      }

      @include vp--639 {
        font-size: 13px;
        line-height: 21px;
      }
    }

    .reviews-item-quality {
      display: flex;
      align-items: baseline;
      font-size: 16px;
      line-height: 24px;
      font-weight: normal;
      color: $color-black;
      margin-bottom: 10px;

      @include vp--959 {
        font-size: 14px;
        line-height: 22px;
      }

      @include vp--639 {
        font-size: 13px;
        line-height: 20px;
      }

      &:last-child {
        margin-bottom: 15px;
      }

      .reviews-item-quality-left {
        font-weight: 600;
        min-width: 110px;
        margin-right: 15px;

        @include vp--639 {
          min-width: 90px;
        }
      }
    }

    .reviews-item-date {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: $color-nobel;

      @include vp--959 {
        font-size: 13px;
        line-height: 18px;
      }

      @include vp--639 {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}