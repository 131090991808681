.card-tooltip {
  right: 0;
  padding: 20px;
  border-radius: 2px;
  background-color: $color-default-white;

  @include vp--639 {
    height: 100%;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 10px;
  }

  &__price {
    font-size: 34px;
    line-height: 40px;
    font-weight: 700;
    color: $color-forest-green;
  }
}
