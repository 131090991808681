.promo-slider {
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background: #FFFFFF;
    opacity: 0.3;

    &-active {
      opacity: 1;
    }
  }
  .promo-slider__pagination {
    bottom: 20px;

    @include vp--959 {
      bottom: 9px;
    }
  }
}

.promo-slider__slide {
  position: relative;
  width: 100%;
  min-height: 360px;
  display: flex;
  align-items: center;
  overflow: hidden;

  img {
    position: absolute;
    object-fit: cover;
    object-position: 55% bottom;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: -1;

    @include vp--959 {
      object-position: 60% 50%;
    }
  }

  @include vp--1279 {
    min-height: 340px;
  }

  @include vp--959 {
    min-height: 240px;
  }

  @include vp--639 {
    min-height: 200px;
  }
}

.promo-slider__content {
  max-width: 700px;
  padding: 23px 0 0;
  font-weight: 700;
  color: $color-default-white;

  p {
    margin: 0;
    font-size: 22px;
    line-height: 23px;
  }

  @include vp--1279 {
    padding-left: 77px;

    p {
      line-height: 28px;
    }
  }

  @include vp--959 {
    padding-left: 55px;
    padding-top: 4px;

    p {
      font-size: 14px;
      line-height: 22px;
    }
  }

  @include vp--639 {
    max-width: 230px;
    padding-left: 0;

    p {
      font-size: 13px;
      line-height: 20px;
      }
    }
}

.promo-slider__title {
  margin: 0;
  margin-bottom: 21px;
  font-size: 50px;
  line-height: 68px;

  @include vp--1279 {
    margin-bottom: 14px;
    font-size: 44px;
    line-height: 55px;
  }

  @include vp--959 {
    margin-bottom: 11px;
  font-size: 34px;
  line-height: 40px;
  }

  @include vp--639 {
    margin-bottom: 4px;
    font-size: 24px;
    line-height: 28px;
  }
}

.promo-slider__button-wrapper {
  position: absolute;
  top: calc(50% - 30px);
  width: 100%;
  max-width: 1360px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: none;

  @include vp--1279 {
    padding: 0 30px;
    top: calc(50% - 16px);
  }

  @include vp--959 {
    top: calc(50% - 24px);
    padding: 0 28px;
  }

  @include vp--639 {
    display: none;
  }
}

.promo-slider__button {
  pointer-events: auto;
  width: 60px;
  height: 60px;


  @include vp--1279 {
    width: 52px;
    height: 52px;
  }

  @include vp--959 {
    width: 40px;
    height: 40px;
  }
}
