.footer {
  min-height: 100px;
  padding-bottom: 28px;
  background-color: $color-myrtle;
  color: $color-default-white;

  @include vp--1279 {
    padding-bottom: 30px;
  }

  @include vp--959 {
    padding-bottom: 36px;
  }
}



