.simple-pagination {
  display: flex;
  align-items: center;

  .simple-pagination-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 50px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: $color-forest-green;
    margin-right: 3px;
    transition: color 0.3s;

    &:hover {
      color: $color-forest-green-2;
      transition: color 0.3s;
    }

    &.active {
      font-weight: bold;
    }

    &:last-child {
      margin-right: 0;
    }

    &.simple-pagination-arrow {
      width: unset;
      height: unset;
      margin-right: 7px;
      transform: rotate(180deg);
    }

    @include vp--767 {
      font-size: 14px;
      line-height: 22px;
      height: 40px;
    }

    @include vp--639 {
      width: 38px;
    }
  }
}