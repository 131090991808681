.callback-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;
  border-radius: 5px;

  @include vp--959 {
    width: 440px;
    padding: 40px 30px 30px;
  }

  @include vp--767 {
    width: 440px;
    padding: 40px 30px 30px;
  }

  @include vp--639 {
    width: 100%;
    border-radius: 0;
    padding: 40px 20px 30px;
  }

  .button {
    width: 100%;

    @include vp--959 {
      height: 46px;
      font-size: 14px;
      line-height: 22px;
    }
  }

  img {
    margin-bottom: 10px;

    @include vp--959 {
      margin-bottom: 8px;
    }

    @include vp--639 {
      width: 280px;
      height: 210px;
      margin-bottom: 11.9vh;
      object-fit: cover;
    }
  }
}

.callback-success__text {
  margin: 0;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 26px;
  text-align: center;

  @include vp--959 {
    font-size: 14px;
    line-height: 24px;
  }

  @include vp--639 {
    margin-bottom: 12.5vh;
  }
}

.callback-success__title {
  margin: 0;
  margin-bottom: 16px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 700;

  @include vp--959 {
    margin-bottom: 5px;
    font-size: 20px;
    line-height: 26px;
  }
}

.callback-success__close-btn {
  top: 20px;
  right: 20px;
  color: $color-black;

  @include vp--959 {
    top: 25px;
    right: 25px;
  }
}
