.modal__inner {
  width: 400px;

  @include vp--959 {
    width: 380px;
  }

  @include vp--767 {
    width: 100%;
  }

  .modal__arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 15px;
    left: 15px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: none;
    outline: none;
    background: transparent;
    padding: 0;
    transition: 0.3s;

    &:hover {
      opacity: .7;
      transition: 0.3s;
    }

    @include vp--639 {
      width: 16px;
      height: 16px;
      top: 18px;
    }

    svg {
      width: 20px;
      height: 20px;

      @include vp--639 {
        width: 16px;
        height: 16px;
      }
    }
  }

  .modal__title {
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    color: $color-black;
    margin-bottom: 30px;

    &.center {
      text-align: center;
      margin-bottom: 15px;

      @include vp--639 {
        max-width: 220px;
        margin: 0 auto;
        margin-bottom: 10px;
      }
    }

    &.first-child {
      margin-bottom: 30px;

      @include vp--639 {
        margin-bottom: 60px;
      }
    }

    @include vp--959 {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 25px;
    }
  
    @include vp--639 {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .modal__text {
    text-align: center;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: $color-black;
    margin-bottom: 30px;

    &.modal__text_bottom {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: $color-nobel;
      margin-bottom: 0;

      @include vp--639 {
        max-width: unset;
      }
    }

    &.modal__text_width {
      @include vp--639 {
        max-width: unset;
        margin-bottom: 35px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    @include vp--959 {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 25px;
    }
  
    @include vp--639 {
      font-size: 13px;
      line-height: 21px;
      max-width: 165px;
      margin: 0 auto;
      margin-bottom: 15px;
    }

    .modal__text-btn {
      border: none;
      outline: none;
      background: transparent;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: $color-forest-green;
      cursor: pointer;
      opacity: 1;
      transition: 0.3s;

      &:hover {
        opacity: 0.7;
        transition: 0.3s;
      }
    }
  }

  .modal__img {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    img {
      width: 272px;
      height: 144px;
      object-fit: cover;

      @include vp--639 {
        width: 280px;
        height: 210px;
      }
    }
  }

  .modal__stars {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    @include vp--959 {
      margin-bottom: 25px;
    }
  
    @include vp--639 {
      margin-bottom: 15px;
    }

    svg {
      width: 30px;
      height: 30px;
      cursor: pointer;

      @include vp--959 {
        width: 20px;
        height: 20px;
      }
    
      @include vp--639 {
        width: 17px;
        height: 17px;
      }

      &:not(:last-child) {
        margin-right: 5px;

        @include vp--959 {
          margin-right: 0;
        }
      }
    }

    span {
      display: inline-block;
      margin-left: 20px;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: $color-black;

      @include vp--639 {
        font-size: 13px;
        line-height: 20px;
      }
    }
  }

  .modal__input {
    &:not(:last-child) {
      margin-bottom: 15px;
    }

    .modal__label {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: $color-dimgray;
      margin-bottom: 5px;

      @include vp--959 {
        font-size: 13px;
        line-height: 18px;
      }
    }

    .custom-input {
      input {
        width: 100%;
      }
    }

    .textarea-input {
      width: 100%;
      height: 158px;
      padding: 8px 15px;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      background-color: $color-default-white;
      border-radius: 2px;
      border: 1px solid rgba($color: $color-black, $alpha: 0.1);
      outline: none;
      transition: background-color $trans--300,
      border-color $trans--300;
      margin-bottom: -6px;

      @include vp--959 {
        font-size: 14px;
        line-height: 24px;
      }
    
      @include vp--639 {
        font-size: 13px;
        line-height: 21px;
      }
    
      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover {
          background-color: $color-saltpan;
        }
      }
    
      &:focus {
        background-color: $color-default-white;
        border-color: $color-forest-green-2;
      }
    }

    .file-input {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }

    .file-input-label {
      display: block;
      width: 100%;
      height: 50px;
      padding: 8px 15px;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      background-color: $color-default-white;
      border-radius: 2px;
      border: 1px solid rgba($color: $color-black, $alpha: 0.1);
      outline: none;
      transition: background-color $trans--300,
      border-color $trans--300;
    
      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover {
          background-color: $color-saltpan;
        }
      }
    
      &:focus {
        background-color: $color-default-white;
        border-color: $color-forest-green-2;
      }
    }

    .error-text {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $color-harley-davidson-orange;
      margin-top: 5px;
      display: none;
      position: relative;      

      .error-text-btn {
        position: absolute;
        top: 6px;
        right: 6px;
        width: 8px;
        height: 8px;
        display: none;
        border: none;
        outline: none;
        background: transparent;
        padding: 0;

        svg {
          width: 8px;
          height: 8px;
        }
      }

      @include vp--639 {
        font-size: 12px;
        line-height: 16px;
        background: $color-harley-davidson-orange;
        border-radius: 2px;
        padding: 7px 30px;
        position: absolute;
        right: 0;
        color: $color-default-white;
        left: 50%;
        transform: translateX(-50%);
        bottom: 20px;
        text-align: center;
        width: 100%;
        max-width: 280px;

        .error-text-btn {
          display: block;
        }
      }
    }

    &.error {
      .custom-input {
        input {
          border: 2px solid $color-harley-davidson-orange;
        }
      }

      .error-text {
        display: block;
      }
    }
  }

  .button {
    width: 100%;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    @include vp--639 {
      font-size: 14px;
      line-height: 22px;
    }
  }
}

.modal-outer {
  @include vp--767 {
    .modal__wrapper {
      align-items: unset;
      padding: 0;
    }
  }
}