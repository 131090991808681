.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  background-color: rgba($color-default-black, 0.6);
  transform: scale(1.2);
  visibility: hidden;
  opacity: 0;

  transition: opacity $trans--600,
    transform $trans--600,
    visibility $trans--600;

  pointer-events: none;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__wrapper {
    position: relative;

    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding: 60px 40px;

    @include vp--767 {
      padding: 60px 16px;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    cursor: pointer;
  }

  &__content {
    position: relative;

    width: 600px;
    padding: 40px;

    background-color: $color-default-white;

    @include vp--767 {
      width: 100%;
      padding: 20px;
    }
  }

  &--responsive {
    .modal__content {
      width: 100%;
    }
  }

  &--fit-content {
    .modal__content {
      width: auto;

      @include vp--767 {
        width: 100%;
        padding-top: 40px;
      }
    }
  }

  &--no-scale {
    transform: none;
  }

  &--preload {
    transition: none;
  }

  &--active {
    transform: scale(1);
    visibility: visible;
    opacity: 1;

    pointer-events: auto;
  }

  &--card-popup .modal__wrapper,
  &--card-tooltip .modal__wrapper {
    align-items: unset;
    justify-content: center;
    min-height: unset;

    @include vp--1279 {
      justify-content: flex-end;
    }
  }

  &--card-popup {
    background-color: $color-transparent;

    .card-popup {
      position: relative;
      opacity: 1;
      z-index: unset;
      visibility: visible;
    }

    .modal__overlay {
      position: fixed;
    }

    .modal__wrapper {

      @include vp--639 {
        padding: 0;
        height: 100%;
      }
    }

    .modal__content {
      width: 543px;
      padding: 0;
      top: 94px;
      left: 335px;
      border-radius: 2px;
      box-shadow: 0 10px 40px 20px rgba(0, 0, 0, 0.1);

      @include vp--1279 {
        top: 86px;
        left: unset;
        right: -1vw;
      }

      @include vp--959 {
        top: 30px;
      }

      @include vp--767 {
        width: 543px;
      }

      @include vp--639 {
        width: 100%;
        right: 0;
      }
    }

    .modal__close-btn {
      top: 14px;
      right: 25px;

      @include vp--639 {
        top: 30px;
        right: 23px;

        svg {
          width: 13px;
          height: 13px;
          color: $color-black;
        }
      }
    }
  }

  &--card-tooltip {
    bottom: unset;
    left: 50%;
    margin-left: 340px;
    background-color: $color-transparent;
    width: 311px;
    min-height: 338px;

    @include vp--1279 {
      left: unset;
      margin-left: unset;
    }

    .modal__wrapper {
      @include vp--639 {
        padding: 0;
      }
    }

    .modal__content {
      width: 211px;
      padding: 0;
      top: 80px;
      border-radius: 2px;
      box-shadow: 0 10px 40px 20px rgba(0, 0, 0, 0.05);

      @include vp--1279 {
        right: -5%;
      }

      @include vp--959 {
        top: 40px;
      }

      @include vp--767 {
        width: 211px;
        right: 5%;
      }

      @include vp--639 {
        top: 30px;
        right: 1%;
      }
    }

    .modal__close-btn {
      opacity: 0;
      visibility: hidden;
      z-index: -1;
      pointer-events: none;
    }

    .modal__overlay {
      z-index: -1;
      pointer-events: none;
    }
  }

  &--callback-form {
    .modal__wrapper {
      padding: 0;

      @include vp--639 {
        align-items: unset;
      }
    }

    @include vp--639 {
      bottom: unset;
      height: 100%;
    }
  }

  &--callback-success {
    .modal__wrapper {
      padding: 0;

      @include vp--639 {
        align-items: unset;
      }
    }

    @include vp--639 {
      bottom: unset;
      height: 100%;
    }
  }
}

.modal__close-btn,
.close-button {
  position: absolute;
  top: 15px;
  right: 15px;

  padding: 0;

  background: $color-transparent;
  border: none;
  cursor: pointer;
  transition: 0.3s;

  &:hover,
  &:focus {
    opacity: 0.7;
    transition: 0.3s;
  }

  &:active {
    fill: $color-night-rider;
  }

  @include vp--639 {
    svg {
      width: 10px;
      height: 10px;
    }
  }
}
