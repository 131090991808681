.block-title {
  display: flex;
  align-items: center;
  margin-top: 50px;

  @include vp--1279 {
    margin-top: 40px;
  }
}

.block-title__title {
  margin: 0;
  margin-right: 38px;
  font-size: 26px;
  line-height: 24px;
  font-weight: 700;
  color: $color-black;

  @include vp--1279 {
    margin-right: 32px;
    font-size: 22px;
    line-height: 28px;
  }

  @include vp--959 {
    margin-right: auto;
    font-size: 24px;
    line-height: 34px;
  }

  @include vp--639 {
    font-size: 20px;
    line-height: 30px;
  }
}
