.advantages__list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 20px);
  margin: 0;
  padding: 50px 0 30px;
  list-style: none;

  @include vp--959 {
    padding: 40px 0 20px;
  }

  @include vp--639 {
    padding: 40px 0 22px;
  }

  &:after {
    position: absolute;
    bottom: 0;
    content: "";
    width: calc(100% - 20px);
    height: 1px;
    background-color: $color-whisper;
  }
}

.advantages__item {
  display: flex;
  width: calc(33.333333% - 20px);
  margin-right: 20px;
  margin-bottom: 20px;

  @include vp--959 {
    width: calc(50% - 20px);
    background-size: cover;

    &:last-child:nth-child(2n+1) {
      width: 100%;
    }
  }

  @include vp--639 {
    width: 100%;
  }
}
