.product-slider {
  padding-top: 50px;

  @include vp--959 {
    padding-top: 41px;
    padding-right: 0;
  }

  @include vp--639 {
    padding-top: 36px;
    padding-right: 0;
  }

  .container {
    position: relative;
  }

  &.border-off {
    .product-slider__wrap:after {
      display: none;
    }
  }
}

.product-slider__wrap {
  position: unset;
  padding-bottom: 22px;

  &:after {
    position: absolute;
    bottom: 0;
    content: "";
    width: calc(100% - 60px);
    height: 1px;
    background-color: $color-whisper;

    @include vp--639 {
      width: calc(100% - 40px);
    }
  }

  @include vp--959 {
    padding-bottom: 15px;
  }

  @include vp--639 {
    padding-bottom: 19px;
  }
}

.product-slider__list {
  display: flex;
  width: calc(100% + 15px);
  margin: 0;
  margin-left: -15px;
  padding: 0;
  list-style: none;

  @include vp--959 {
    margin-left: -7.5px;
  }
}

.product-slider__item {
  flex-shrink: 1;
  align-items: stretch;
}

.product-slider .button--next,
.product-slider .button--prev {
  position: absolute;
  top: 49%;
  width: 62px;
  height: 62px;
  transition: opacity $trans--300;
  pointer-events: all;
  z-index: 100;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: $color-transparent;

  @include vp--1279 {
    width: 52px;
    height: 52px;
  }

  @include vp--959 {
    top: 47%;
  }

  @include vp--959 {
    display: none;
  }

  &.swiper-button-disabled {
    opacity: 0;
    pointer-events: none;
  }
}

.product-slider .button--next {
  right: 0;

  @include vp--1279 {
    right: 15px;
  }
}

.product-slider .button--prev {
  left: 0;

  @include vp--1279 {
    left: 15px;
  }
}
