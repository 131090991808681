.mobile-menu {
  width: 320px;
  min-height: 100vh;
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: $color-default-white;
  transform: translateX(-100%);
  transition: $trans--300;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &.active {
    transform: translateX(0);
    box-shadow: 0 10px 40px 20px rgba(0, 0, 0, 0.05);
    overflow-y: auto;
  }

  @include vp--639 {
    width: 100%;
  }

  .container {
    padding: 15px 20px 30px 20px;

    @include vp--639 {
      padding: 20px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 11px;

    img {
      width: 67px;
      height: 47px;
    }
  }

  &__logo {
    @include vp--639 {
      display: none;
    }
  }

  &__entry.link,
  &__exit.link {
    display: none;

    @include vp--639 {
      position: relative;
      display: flex;
      flex-direction: row-reverse;
    }
  }

  &__entry.link {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;

    svg {
      margin-right: 10px;
    }
  }

  &__exit.link {
    font-weight: 500;
    justify-content: flex-end;
    margin-top: 15px;

    svg {
      margin-right: 5px;
    }
  }

  &__button {
    width: 100%;
    margin-top: 18px;

    display: flex;
    flex-direction: row-reverse;
    justify-content: center;

    padding: 11px 20px;
  }

  &__item {
    padding-bottom: 7px;
    padding-top: 7px;
  }

  &__item:nth-child(3),
  &__item:nth-child(6) {
    padding-top: 15px;
  }

  &__item:nth-child(2),
  &__item:nth-child(5),
  &__item:last-child {
    padding-bottom: 15px;
    border-bottom: 1px solid $color-whisper;
  }

  &__link {
    font-weight: 500;
  }

  &__contacts {
    padding-top: 69%;

    @include vp--639 {
      padding-top: 33px;
    }
  }

  &__contacts-list {
    display: flex;
    margin-bottom: 20px;
  }

  &__contacts-item {
    margin-right: 20px;
  }

  &__tel.link {
    @include vp--959 {
      margin-bottom: 22px;
      font-size: 18px;
      line-height: 16px;
      font-weight: 700;
      color: $color-night-rider;
    }
  }

  &__call {
    font-size: 13px;
    line-height: 18px;
    padding: 0;
    margin: 0;
    margin-bottom: 8px;
    color: $color-dimgray;
    background-color: $color-transparent;
    border: none;
    outline: none;
    -webkit-tap-highlight-color: $color-transparent;
    transition: color $trans--300;
    cursor: pointer;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-black;
      }

      &:active {
        color: $color-night-rider;
      }
    }
  }

  ul {
    padding: 0;
    list-style: none;
  }

  .close-button {
    color: $color-default-black;
    z-index: 100;
    width: 20px;
    height: 20px;
    top: 20px;
    right: 20px;
  }
}

