.button {
  padding: 13px 20px;
  border: none;
  display: inline-block;
  border-radius: 2px;
  background-color: $color-forest-green;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $color-default-white;
  cursor: pointer;
  outline: none;
  transition: background-color $trans--300;

  svg {
    opacity: 1;
    transition: 0.3s;
  }

  @include vp--639 {
    padding: 12px 20px;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      background-color: $color-forest-green-2;

      svg {
        opacity: 0.8;
        transition: 0.3s;
      }
    }

    &:active {
      background-color: $color-camarone;
    }
  }

  &:disabled,
  &.disabled {
    background-color: $color-nobel;
    pointer-events: none;
  }

  &--catalog {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    padding: 13px 15px;

    @include vp--1279 {
      padding-right: 20px;
    }

    @include vp--959 {
      position: absolute;
      top: -112px;
      z-index: 5;
      font-size: 0;
      line-height: 0;
      padding: 10px;
    }

    @include vp--639 {
      padding: 0;
      top: -36px;
      background-color: $color-transparent;
      color: $color-black;
    }

    svg {
      margin-right: 7px;

      @include vp--959 {
        margin-right: 0;
      }
    }
  }

  &--open-catalog {
    svg {
      margin-right: 10px;
    }
  }

  &--icon {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    svg {
      display: block;
      flex-shrink: 0;
      flex-grow: 0;
    }

    @include vp--959 {
      height: 46px;
    }
  }

  &--search {
    border-radius: 0 3px 3px 0;

    @include vp--959 {
      height: 40px;
      width: 43px;
    }

    @include vp--639 {
      padding: 0;
      background-color: $color-transparent;
      color: $color-black;
      position: absolute;
      top: -45px;
      left: 24px;
    }
  }

  &--cart {
    @include vp--959 {
      font-size: 14px;
      line-height: 22px;
    }
  }

  &--next,
  &--prev {
    width: 62px;
    height: 62px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    outline: none;
    border: none;

    @include vp--1279 {
      width: 52px;
      height: 52px;
    }

    svg {
      display: block;
      flex-shrink: 0;
      flex-grow: 0;
    }
  }

  &--prev svg {
    transform: rotate(180deg);
  }

  &--christi {
    flex-shrink: 0;
    color: $color-forest-green;
    background-color: rgba($color: $color-christi, $alpha: 0.1);
    transition: color $trans--300,
      background-color $trans--300;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-forest-green-2;
        background-color: rgba($color: $color-christi, $alpha: 0.1);
      }
    }

    &:active {
      color: $color-forest-green;
      background-color: rgba($color: $color-christi, $alpha: 0.2);
    }

    &:disabled,
    &.disabled {
      opacity: 0.5;
      color: $color-forest-green;
      background-color: rgba($color: $color-christi, $alpha: 0.1);
      pointer-events: none;
    }
  }

  &--arrows {
    height: 50px;

    @include vp--959 {
      padding: 18px 17px;
    }

    @include vp--639 {
      padding: 18px 17px;
    }

    svg {
      display: none;

      @include vp--959 {
        display: block;
      }
    }

    span {
      @include vp--959 {
        display: none;
      }
    }
  }

  &--minus,
  &--plus {
    &:disabled,
    &.disabled {
      background-color: rgba($color-christi, 0.1);
      opacity: 1;

      svg {
        opacity: 0.4;

        @include vp--959 {
          width: 12px;
          height: 2px;
        }
      }
    }
  }

  &--minus-small-size,
  &--plus-small-size {
    width: 30px;
  }

  &--minus-small-size {
    padding-left: 6px;
  }

  &--plus-small-size {
    padding-right: 6px;

    @include vp--1279 {
      padding-right: 11px;
    }
  }

  &--price {
    padding: 6px 8px;
    font-size: 10px;
    line-height: 12px;
    border-radius: 6px;
  }

  &--semitransparent {
    width: max-content;
    background-color: rgba($color: $color-default-white, $alpha: 0.2);

    @media (hover: hover), screen and (min-width: 0\0) {

      &:hover {
        background-color: rgba($color: $color-default-white, $alpha: 0.1);
      }

      &:focus {
        background-color: rgba($color: $color-default-white, $alpha: 0.4);
      }
    }
  }

  &--service {
    padding: 0;
    color: $color-christi;
    background-color: $color-transparent;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-forest-green-2;
        background-color: $color-transparent;
      }

      &:active {
        color: $color-forest-green;
        background-color: $color-transparent;
      }
    }

    &.is-active {
      color: $color-harley-davidson-orange;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-harley-davidson-orange;
        }
      }
    }
  }

  &--prev-header {
    display: none;

    @include vp--959 {
      display: block;
      width: 42px;
      height: 42px;
      left: 10px;
      padding: 11px 10px;
      position: absolute;
      color: $color-default-black;
      background-color: $color-transparent;

      &:hover,
      &:focus {
        color: $color-dimgray;
        background-color: $color-transparent;
      }

      &:active {
        color: $color-night-rider;
        background-color: $color-transparent;
      }
    }
  }

  &.js-service--active {
    display: none;
  }

  &.button--title-button {
    padding: 8px 15px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    border: 1px solid $color-whisper;
    color: $color-black;
    background-color: $color-default-white;
    transition: all $trans--300;
    cursor: pointer;

    @include vp--959 {
      padding: 6px 11px;
      font-size: 14px;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover ,
      &:focus {
        background-color: $color-default-white;
        border: 1px solid $color-forest-green;
      }

      &:active {
        background-color: $color-forest-green;
        border: 1px solid $color-default-white;
      }
    }

    &.is-active {
      color: $color-black;
      border: 1px solid  $color-forest-green;
      box-shadow: 0 0 0 1px $color-forest-green;
    }
  }
}
