.about-us {
  background-color: rgba(244, 244, 244, 0.5);

  @include vp--959 {
    padding-bottom: 20px;
  }

  @include vp--639 {
    padding-bottom: 0;
  }
}

.about-us__wrapper {
  display: flex;
  padding: 50px 0;

  @include vp--959 {
    padding: 40px 0;
  }

  @include vp--639 {
    flex-direction: column;
  }
}

.about-us__description {
  margin: 0;
  line-height: 26px;

  &:not(&:last-child) {
    margin-right: 33px;

    @include vp--1279 {
      margin-right: 55px;
    }

    @include vp--959 {
      margin-right: 5px;
    }

    @include vp--639 {
        margin-right: 0;
        margin-bottom: 15px;
    }
  }

  @include vp--959 {
    font-size: 14px;
    line-height: 24px;

  }

  @include vp--639 {
    line-height: 22px;
  }
}
