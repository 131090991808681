.contacts {
  display: flex;
  flex-direction: column;

  @include vp--959 {
  }

  @include vp--639 {
    flex-wrap: nowrap;
    align-items: center;
    max-height: max-content;
  }
}

.contacts__tel {
  width: max-content;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  @include vp--1279 {
    padding-bottom: 18px;
  }

  @include vp--959 {
    padding-bottom: 10px;
  }

  @include vp--639 {
    padding-bottom: 0;
    border-bottom: none;
    text-align: center;
    border-bottom: none;
  }
}

.contacts__tel-link {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: $color-default-white;

  &:hover {
    opacity: 0.8;
    transition: $trans--300;
  }

  &:active {
    opacity: 0.6;
    transition: $trans--300;
  }
  @include vp--1279 {
    font-size: 20px;
    line-height: 26px;
  }
}

.contacts__tel-description {
  margin: 0;
  line-height: 26px;
  opacity: 0.5;

  @include vp--1279 {
    font-size: 14px;
    line-height: 22px;
    margin-top: 7px;
  }

  @include vp--959 {
    margin-top: 2px;
  }

  @include vp--639 {
    margin-top: 5px;
    font-size: 12px;
    line-height: 26px;
  }
}

.contacts__email {
  padding: 12px 0 17px;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.8);

  &:hover {
    opacity: 0.8;
    transition: $trans--300;
  }

  &:active {
    opacity: 0.6;
    transition: $trans--300;
  }

  @include vp--1279 {
    font-size: 14px;
    line-height: 22px;
  }

  @include vp--639 {
    font-size: 12px;
    line-height: 26px;
    padding: 0;
  }
}

.contacts__social {
  display: flex;
  margin: 0;
  padding: 15px 0 0;
  list-style: none;

  @include vp--959 {
    padding-top: 6px;
  }

  @include vp--639 {
    padding: 21px 0 1px;
  }
}

.contacts__social-item:not(:last-child) {
  margin-right: 20px;

  @include vp--639 {
    margin-right: 30px;
  }
}

.contacts__social-link {
  color: $color-default-white;

  &:hover {
    opacity: 0.8;
    transition: $trans--300;
  }

  &:active {
    opacity: 0.6;
    transition: $trans--300;
  }

}
