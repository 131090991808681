.promo-block {
  position: relative;
  width: 100%;
  padding: 50px 0;


  @include vp--959 {
    padding-top: 30px;
    padding-bottom: 0;
  }

  @include vp--639 {
    padding-top: 39px;
  }
}

.promo-block .container {
  position: relative;

  &:after {
    position: absolute;
    bottom: -50px;
    content: "";
    width: calc(100% - 60px);
    height: 1px;
    background-color: $color-whisper;

    @include vp--959 {
      bottom: 0;
    }

    @include vp--639 {
      width: calc(100% - 40px);
    }
  }
}

.promo-block__wrapper {

  @include vp--959 {
    overflow-x: auto;
    scrollbar-width: thin;
    margin-right: -30px;
    margin-left: -30px;
  }
}

.promo-block__title {
  margin: 8px 0 48px;
  font-size: 26px;
  line-height: 34px;
  font-weight: 700;

  @include vp--1279 {
    margin: 8px 0 30px;
    font-size: 22px;
    line-height: 28px;
  }

  @include vp--959 {
    margin: 19px 0 38px;
    font-size: 24px;
    line-height: 34px;
  }

  @include vp--639 {
    margin: 0 0 30px;
    font-size: 22px;
    line-height: 30px;
  }

}

.promo-block__list {
  display: flex;
  width: calc(100% + 20px);
  padding: 0;
  margin: 0;
  list-style: none;

  @include vp--959 {
    width: max-content;
    padding: 0 30px 40px;
  }
}

.promo-block__item {
  display: flex;
  width: calc(20% - 20px);

  &:not(&:last-child){
    margin-right: 20px;
  }

  @include vp--959 {
    width: 180px;
  }

  @include vp--639 {
    width: 150px;

    &:not(&:last-child){
      margin-right: 11px;
    }
  }
}

