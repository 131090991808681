.city-popup {
  width: 100%;
  min-height: 100vh;
  padding-top: 50px;
  padding-bottom: 75px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;
  background-color: $color-default-white;
  display: none;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &.modal--active {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
  }

  @include vp--1279 {
    padding-top: 42px;
  }

  @include vp--639 {
    padding-top: 45px;
  }

  .close-button {
    top: 50px;
    right: 50px;
    color: $color-default-black;

    @include vp--1279 {
      top: 30px;
      right: 30px;
    }

    @include vp--639 {
      top: 20px;
      right: 20px;
    }

    svg {
      @include vp--1279 {
        width: 24px;
        height: 24px;
      }

      @include vp--959 {
        width: 20px;
        height: 20px;
      }

      @include vp--639 {
        width: 16px;
        height: 16px;
      }
    }
  }

  &__text {
    display: flex;
    align-items: center;

    @include vp--639 {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 24px;
    }
  }

  &__title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    margin-right: 8px;

    @include vp--639 {
      margin-bottom: 0;
    }
  }

  &__selected-city {
    line-height: 22px;
    font-weight: 500;
    color: $color-default-black;
  }

  &__main-list {
    max-height: 100%;
    columns: 3;
    column-gap: 90px;
    padding-top: 31px;

    @include vp--639 {
      columns: 2;
      column-gap: 30px;
    }
  }

  &__main-item--block {
    margin-top: 31px;
    break-inside: avoid-column;
  }

  &__main-title {
    display: inline-block;
    font-size: 22px;
    line-height: 28px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &__main-link {
    line-height: 26px;

    &.active {
      color: $color-night-rider;
    }
  }
}

ul {
  margin: 0;
  padding: 0;

  list-style: none;
}

.custom-input--color-transparent input {
  width: 354px;

  @include vp--639 {
    width: 100%;
  }
}
