.copyright {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;

  @include vp--1279 {
    align-items: end;
  }

  @include vp--959 {
    align-items: center;
    padding-right: 22px;
  }

  @include vp--639 {
    flex-direction: column;
  }
}

.copyright__wrapper {
  display: flex;

  @include vp--1279 {
    flex-direction: column;
  }
}

.copyright__list {
  display: flex;
  justify-content: start;
  margin: 0;
  padding: 0;
  list-style: none;

  @include vp--959 {
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 32px;
  }

  @include vp--639 {
    max-height: max-content;
  }
}

.copyright__link-wrapper {
  margin-right: 25px;

  @include vp--1279 {
    margin-right: 20px;
   }

  @include vp--959 {
    margin-right: 30px;
    max-width: 190px;
  }

  @include vp--639 {
    margin-right: 0;
  }
}

.copyright__link {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.5);
  display: block;

  &:hover {
    opacity: 0.8;
    transition: $trans--300;
  }

  &:active {
    opacity: 0.6;
    transition: $trans--300;
  }

  @include vp--1279 {
    font-size: 11px;
  }

  @include vp--639 {
    text-align: center;
    margin-bottom: 7px;
  }
}

.copyright__main {
  display: block;
  margin-right: 25px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: $color-default-white;

  @include vp--1279 {
    margin-right: 0;
    margin-bottom: 10px;
  }

  @include vp--639 {
    margin-bottom: 7px;
    text-align: center;
  }
}

.copyright__developer-logo {
  color: $color-default-white
}


