.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;

  @include vp--374 {
    margin-bottom: 20px;
  }

  .breadcrumbs-item {
    line-height: 26px;
    color: #696969;

    @include vp--767 {
      font-size: 14px;
      line-height: 24px;
    }
  
    @include vp--374 {
      font-size: 13px;
      line-height: 21px;
    }

    &::after {
      content: "/";
      margin-left: 6px;
      margin-right: 6px;
    }
  }
}