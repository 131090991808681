.city-tooltip {
  width: 293px;
  padding: 10px 15px;

  position: absolute;
  z-index: 100;
  top: calc(100% + 20px);
  left: -150px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  // visibility: hidden;

  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: $color-default-white;

  @include vp--639 {
    width: 280px;
    top: calc(100% + 10px);
    padding: 8px 10px;
    left: -160px;
  }

  &.hidden {
    visibility: hidden;
  }

  &__text {
    font-size: 12px;
    line-height: 16px;
    margin: 0;
  }

  &__buttons {
    display: flex;
  }

  &__button {
    padding: 7px 12px;

    font-size: 12px;
    line-height: 16px;
    font-weight: 600;

    min-width: 45px;

    @include vp--639 {
      padding: 7px 12px;
    }

    &--no {
      color: $color-black;
      background-color: $color-default-white;
      border: 1px solid $color-whisper;
      transition: border $trans--300;
      margin-left: 5px;

      &.button:hover,
      &.button:focus {
        border: 1px solid $color-forest-green;
        background-color: $color-transparent;
      }
    }
  }
}
