.header {
  display: flex;
  align-items: center;
  width: 100vw;
  position: fixed;
  z-index: 200;
  background-color: $color-default-white;
  box-shadow: 0 10px 40px 20px rgba(0, 0, 0, 0.05);

  font-size: 14px;
  line-height: 20px;

  min-height: 235px;

  @include vp--1279 {
    min-height: 241px;
  }

  @include vp--959 {
    min-height: 155px;
  }

  @include vp--639 {
    min-height: 80px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
  }

  &__container {
    width: 100%;
    background-color: $color-forest-green;

    z-index: 10;
  }

  &__info {
    display: flex;
    justify-content: space-between;

    padding-top: 11px;
    padding-bottom: 11px;

    @include vp--959 {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    @include vp--639 {
      padding-top: 6px;
      padding-bottom: 4px;
    }

    .header__acc-info {
      display: flex;
      position: relative;
    }

    .header__acc,
    .header__select-city {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      position: relative;

      margin-left: 20px;

      @include vp--959 {
        margin-left: 13px;
      }

      @include vp--639 {
        margin-left: 0;
      }
    }

    .header__arrow {
      margin-left: 10px;
      order: -5;
    }

    .header__acc {
      @include vp--639 {
        display: none;
      }
    }
  }

  &__main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid $color-white-smoke-2;
    z-index: 5;
    row-gap: 30px;

    padding-top: 20px;
    padding-bottom: 22px;

    @include vp--1279 {
      padding-top: 21px;
      padding-bottom: 15px;
    }

    @include vp--959 {
      display: grid;
      grid-template-areas: ". logo acc"
                           "search search search";
      grid-template-columns: 140px 1fr 140px;
      row-gap: 8px;

      border-bottom: none;

      padding-top: 15px;
      padding-bottom: 15px;
    }

    @include vp--639 {
      grid-template-columns: 50px 180px 50px;
      row-gap: 7px;

      padding-top: 8px;
      padding-bottom: 0;
    }

    .header__logo {
      margin-right: 33px;

      @include vp--1279 {
        width: 80px;
        height: 56px;
        margin-right: 20px;
      }

      @include vp--959 {
        width: 67px;
        height: 47px;

        grid-area: logo;
        justify-self: center;

        margin-right: 0;
      }

      @include vp--639 {
        width: 50px;
        height: 35px;
        margin: 0 auto;
      }
    }

    .header__contacts {
      display: flex;
      flex-direction: column;
      margin-right: auto;

      @include vp--959 {
        display: none;
      }
    }

    .header__tel {
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;

      @include vp--1279 {
        font-size: 20px;
        line-height: 26px;
      }
    }

    .header__call {
      display: flex;
      padding: 0;
      margin: 0;
      color: $color-dimgray;
      background-color: $color-transparent;
      border: none;
      outline: none;
      -webkit-tap-highlight-color: $color-transparent;
      transition: color $trans--300;
      cursor: pointer;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-black;
        }

        &:active {
          color: $color-night-rider;
        }
      }
    }

    .header__acc-list {
      display: flex;
      padding-top: 19px;

      @include vp--959 {
        grid-area: acc;
        padding-top: 0;
      }

      @include vp--639 {
        // order: 3;
      }

      li:not(:last-child) {
        @include vp--959 {
          margin-right: 30px;
        }
      }

      li:last-child {
        @include vp--959 {
          margin-right: 0;
        }
      }
    }

    .header__acc-item:not(:last-child) {
      margin-right: 20px;
    }

    .header__acc-item--compare,
    .header__acc-item--favorite {
      @include vp--639 {
        display: none;
      }
    }

    .header__acc-item--card {
      position: relative;

      @include vp--639 {
        padding-left: 5px;
      }

      .card-tooltip {
        position: absolute;
        display: none;
        transition: all $trans--300;
         z-index: 101;

        .modal__close-btn {
          display: none;
        }
      }

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover .card-tooltip,
        &:focus .card-tooltip {
          display: block;
        }
      }
    }

    .header__acc-link {
      display: flex;
      flex-direction: column-reverse;

      position: relative;

      @include vp--959 {
        display: block;
        font-size: 0;
        line-height: 0;
      }

      svg {
        margin-bottom: 5px;

        @include vp--959 {
          margin-bottom: 0;
        }
      }
    }

    // счётчик
    .header__acc-counter {
      position: absolute;
      top: -10px;
      left: 67%;
      padding: 0 5.5px;
      border-radius: 10px;

      font-size: 13px;
      line-height: 18px;
      font-weight: 600;

      color: $color-default-white;
      background-color: $color-forest-green;

      @include vp--959 {
        left: 111%;
        font-size: 10px;
        line-height: 15px;
      }

      @include vp--639 {
        top: 3px;
        left: 120%;
      }
    }
  }

  &__catalog {
    display: flex;
    position: relative;

    padding-top: 15px;
    padding-bottom: 15px;

    @include vp--1279 {
      padding-top: 19px;
      padding-bottom: 23px;
    }

    @include vp--959 {
      padding-top: 0;
      padding-bottom: 0;
    }

    .header__catalog-list {
      display: flex;
      width: 100%;
      margin-left: 20px;

      @include vp--959 {
        display: none;
      }
    }

    .header__catalog-item {
      margin-right: 20px;

      &:nth-child(6n) {
        margin-left: auto;
      }

      @include vp--1279 {
        &:nth-child(4n),
        &:nth-child(5n) {
          display: none;
        }
      }
    }

    .header__catalog-link {
      display: flex;
      flex-direction: row-reverse;

      font-weight: 600;

      svg {
        margin-right: 8px;
        fill: $color-transparent;
      }
    }
  }

  .button--catalog .button-burger {
    display: none;

    @include vp--959 {
      display: block;
    }
  }

  .button--catalog .button-menu {
    @include vp--959 {
      display: none;
    }
  }

  .button--catalog:hover,
  .button--search:hover,
  .button--catalog:focus,
  .button--search:focus {
    @include vp--639 {
      background-color: $color-transparent;
    }
  }

  .button--catalog:active,
  .button--search:active,
  .button--search.active {
    @include vp--639 {
      background-color: $color-transparent;
      color: $color-forest-green;
    }
  }

  .button--search.active {
    @include vp--639 {
      top: -53px;
      left: 24px;
    }
  }

  .button--search-mobile {
    display: none;

    @include vp--639 {
      // display: block;
      width: 40px;
      height: 40px;
      padding: 10px;
      color: $color-black;
      background-color: rgba($color: $color-christi, $alpha: 0.1);
    }

    &:hover,
    &:focus {
      background-color: rgba($color: $color-christi, $alpha: 0.1);
    }
  }

  ul {
    // display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;

    list-style: none;
  }
}
