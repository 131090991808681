.payment-methods {

  @include vp--639 {
  left: 0;
  }
}

.payment-methods__wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: calc(170px + 10px);

  @include vp--1279 {
    width: 147px;
  }
  @include vp--639 {
    width: 140px;
    margin: 0 auto;
    padding-top: 15px;
  }
}

.payment-methods__title {
  margin: 0 0 25px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;

  @include vp--1279 {
    font-size: 14px;
    margin-bottom: 15px;

  }

  @include vp--959 {
    margin-bottom: 15px;
  }

  @include vp--639 {
    display: none;
  }
}

.payment-methods__item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(50% - 10px);
  height: 50px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;


  @include vp--1279 {
    height: 40px;

    &:nth-child(2)
      svg {
        width: 30px;
        height: 19px;
      }
  }
}
