.container {
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
  padding: 0 30px;

  @include vp--639 {
    padding: 0 20px;
  }

  &--no-padding {
    @include vp--639 {
      padding: 0;
    }
  }
}

.content {
  padding-bottom: 50px;

  @include vp--959 {
    padding-bottom: 40px;
  }
}
