.catalog-lvl {
  .items-number {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;

    @include vp--639 {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      // Ниже стили если не понравится растянутый вид кнопок
      // max-width: 320px;
      // margin: 0 auto;
    }

    .title {
      font-size: 22px;
      line-height: 28px;
      color: $color-black;
      font-weight: bold;

      @include vp--767 {
        font-size: 20px;
        line-height: 26px;
      }

      @include vp--639 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 15px;
      }
    }

    .btns {
      display: flex;
      align-items: center;

      @include vp--639 {
        width: 100%;
        flex-direction: column;
      }

      .filter-btn {
        display: none;        
        align-items: center;
        justify-content: center;
        margin-left: 10px;

        svg {
          margin-right: 6px;
        }

        @include vp--959 {
          display: flex;
        }

        @include vp--767 {
          padding: 8px 14px;
          font-size: 14px;
          line-height: 22px;
        }

        @include vp--639 {
          width: 100%;
          margin-left: 0;
          margin-top: 10px;
        }
      }
    }
  }

  .catalog-lvl--inner {
    display: flex;
    margin-bottom: 35px;

    .filter-block {
      margin-right: 25px;
      width: 320px;
      min-width: 320px;
      border: 1px solid $color-whisper;
      border-radius: 2px;
      padding: 30px;
      height: max-content;
      transition: left 0.5s;
      background: $color-default-white;

      .filter-block-cross {
        display: none;
      }

      .devider {
        width: 100%;
        height: 1px;
        background: #ECEBEB;
        margin-top: 20px;
        margin-bottom: 15px;

        @include vp--639 {
          margin-top: 15px;
        }
      }

      .inputs-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .custom-input--filter {
          input {
            text-align: center;
            max-width: 126px;

            @include vp--767 {
              height: 46px;
            }
          }          
        }
      }

      .accordeon {
        .accordeon-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          transition: 0.3s;
          
          &.active {
            margin-bottom: 15px;
            transition: 0.3s;
          }

          span {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;

            @include vp--639 {
              font-size: 14px;
              line-height: 22px;
            }
          }

          .arrow {
            width: 8px;
            height: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: transform 0.3s;

            @include vp--767 {
              width: 6px;
              height: 4px;
            }
          }

          &.active {
            .arrow {
              transform: rotate(180deg);
              transition: transform 0.3s;
            }
          }
        }

        .accordeon-body {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.2s ease-out;

          .btns-wrapper {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -5px;            

            .button--title-button {
              display: flex;
              align-items: center;
              padding: 4px 10px;
              font-size: 14px;
              line-height: 22px;
              margin-left: 5px;
              margin-right: 5px;
              margin-bottom: 10px;
              height: 32px;

              @include vp--767 {
                height: 30px;
              }

              &.is-active {
                border: 2px solid #237804;
                box-shadow: unset;
              }

              svg {
                margin-left: 6px;
              }
            }

            .button--christi {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 5px;
              height: 48px;
              font-size: 16px;
              line-height: 22px;
              margin-right: 50px;
              margin-left: 5px;

              @include vp--767 {
                height: 40px;
                font-size: 14px;
              }
            }
          }
        }
      }

      .filter-block-btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        &.desktop {
          height: 54px;

          @include vp--767 {
            display: none;
          }
        }

        &.mobile {
          height: 44px;
          font-size: 14px;
          line-height: 22px;
          display: none;

          @include vp--767 {
            display: flex;
          }

          @include vp--639 {
            height: 40px;
          }
        }

        &.button--christi {
          color: $color-black;

          svg {
            fill: $color-black;
          }
        }

        svg {
          margin-right: 13px;
          fill: $color-default-white;
        }
      }

      @include vp--959 {
        position: fixed;
        left: -100%;
        height: 100vh;
        top: 0;
        bottom: 0;
        z-index: 1111;
        transition: left 0.5s;
        overflow-x: hidden;
        overflow-y: auto;
        border: none;
        padding-top: 70px;

        .filter-block-cross {
          display: inline-block;
          position: absolute;
          top: 14px;
          right: 21px;
        }
      }

      &.open {
        left: 0;
        transition: left 0.5s;
      }
    }

    .items-block {
      margin: 0 -15px;
      margin-left: auto;
      flex-grow: 1;
      display: flex;
      flex-wrap: wrap;

      @include vp--959 {
        .product-card {
          width: 200px;
          margin-right: 50px;
          margin-bottom: 50px;   

          .product-card__wrap {
            min-height: 428px;
            display: flex;
            flex-direction: column;

            .product-card__img {
              height: 180px;
              margin-bottom: 20px;
            }

            .product-card__block {
              .product-card__price {
                font-size: 24px;

                .product-card__price-sale {
                  display: block;
                  margin-left: 0;
                }
              }

              .product-card__point,
              .product-card__number {
                font-size: 13px;
                line-height: 18px;
              }

              .product-card__title {
                font-size: 14px;
                line-height: 22px;
              }

              &.product-card__block--min-height {
                min-height: unset;
              }
            }

            .product-card__form {
              margin-top: auto;

              .product-card__count {
                margin-top: 5px;

                .button--minus-small-size, 
                .button--plus-small-size {
                  width: 25px;
                  height: 44px;
                }

                .custom-input input {
                  width: 33px;
                  height: 44px;
                }
              }

              .button--cart {
                font-size: 14px;
                height: 44px;
                white-space: nowrap;
                max-width: 94px;

                svg {
                  display: none;
                }
              }
            }
          }
        }
      }

      @include vp--639 {
        .product-card {
          width: 130px;
          margin-right: 15px;
          margin-bottom: 30px;   

          .product-card__wrap {
            min-height: 353px;

            .product-card__img {
              height: 121px;
              margin-bottom: 16px;
            }

            .product-card__block {
              .product-card__price {
                font-size: 18px;
                line-height: 24px;

                .product-card__price-sale {
                  font-size: 12px;
                }
              }

              .product-card__point,
              .product-card__number {
                font-size: 10px;
                line-height: 12px;
              }

              .product-card__title {
                font-size: 13px;
                line-height: 18px;
              }
            }

            .product-card__form {
              .product-card__count {
                flex-wrap: wrap;
                margin-top: 10px;

                .button--minus-small-size, 
                .button--plus-small-size {
                  width: 38px;
                }

                .custom-input input {
                  width: 34px;
                }
              }

              .button--cart {
                width: 100%;
                max-width: unset;
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
  }

  .tabs-wrapper {
    max-width: 830px;
    margin-bottom: 50px;
  }
}

.up-btn-wrapper {
  box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(10,10,10,.05);
  padding: 20px 0;

  @include vp--767 {
    padding: 10px 0;
  }

  .up-btn-inner {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &.double {
      justify-content: space-between;
    }

    .button {
      @include vp--767 {
        padding: 8px 12px;
      }      
    }
  }
}

.promotion-description {  
  position: relative;
  margin-bottom: 40px;
  padding-bottom: 50px;
  padding-right: 120px;

  @include vp--639 {
    padding-right: 0;
    padding-bottom: 40px;
  }

  .promotion-description-block {
    font-size: 18px;
    line-height: 30px;
    color: $color-black;
    font-style: normal;
    font-weight: normal;

    @include vp--767 {
      font-size: 16px;
      line-height: 28px;
    }
  
    @include vp--639 {
      font-size: 13px;
      line-height: 21px;
    }

    &:not(:last-child) {
      margin-bottom: 20px;

      @include vp--767 {
        margin-bottom: 10px;
      }
    
      @include vp--639 {
        margin-bottom: 5px;
      }
    }
  }

  &::after {
    position: absolute;
    bottom: 0;
    content: "";
    width: 100%;
    height: 1px;
    background-color: #ecebeb;
  }
}