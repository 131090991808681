.checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $color-black;
  margin-bottom: 17px;
  transition: color 0.1s;

  @include vp--767 {
    font-size: 13px;
    line-height: 16px;
    padding-left: 25px;
  }
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: $color-default-white;  
  border: 1px solid $color-whisper;
  border-radius: 2px;
  transition: border 0.05s;

  @include vp--767 {
    width: 16px;
    height: 16px;
  }
}

// .checkbox:hover {
//   color: $color-dimgray;
//   transition: color 0.1s;
// }

// .checkbox:hover input ~ .checkmark {
//   border: 2px solid $color-forest-green;
//   transition: border 0.05s;
// }

.checkbox input:checked ~ .checkmark {
  border: 2px solid $color-forest-green;
  transition: border 0.05s;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox input:checked ~ .checkmark:after {
  display: block;
}

.checkbox .checkmark:after {
  left: 5px;
  top: 2px;
  width: 6px;
  height: 10px;
  border: solid #0a0a0a;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);

  @include vp--767 {
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border-width: 0px 1px 1px 0;
  }    
}