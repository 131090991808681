.pagination {
  display: flex;
  align-items: center;

  .pagination-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #696969;
    margin-right: 10px;
    border: 1px solid $color-whisper;
    transition: 0.3s;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #696969;
      width: 100%;
      height: 100%;

      @include vp--767 {
        font-size: 14px;
        line-height: 20px;
      }

      @include vp--639 {
        font-size: 13px;
        line-height: 18px;
      }
    }

    &:hover {
      border: 1px solid #cdcdcd;
      transition: 0.3s;
    }

    &.active {
      border: 2px solid $color-forest-green;
      font-weight: bold;
      color: $color-black;
    }

    &:last-child {
      margin-right: 0;
    }

    @include vp--767 {
      width: 38px;
      height: 38px;
    }

    @include vp--639 {
      width: 36px;
      height: 36px;
    }
  }
}