// Vendor
//---------------------------------

@import 'vendor/normalize';
@import 'vendor/swiper';

// Global
//---------------------------------

@import 'variables';
@import 'mixins';
@import 'global/fonts';
@import 'global/reboot';
@import 'global/utils';
@import 'global/container';

// Blocks
//---------------------------------

@import 'blocks/header';
@import 'blocks/main-nav';
@import 'blocks/footer';
@import 'blocks/modal';
@import 'blocks/page';
@import 'blocks/button';
@import 'blocks/custom-input';
@import 'blocks/subscription';
@import 'blocks/contacts';
@import 'blocks/mobile-menu';
@import 'blocks/nav-footer';
@import 'blocks/payment-methods';
@import 'blocks/card-tooltip';
@import 'blocks/profile-tooltip';
@import 'blocks/city-tooltip';
@import 'blocks/catalog';
@import 'blocks/product-card';
@import 'blocks/copyright';
@import 'blocks/block-title';
@import 'blocks/promotions-title';
@import 'blocks/product-slider';
@import 'blocks/search';
@import 'blocks/city-popup';
@import 'blocks/promo-slider';
@import 'blocks/advantages';
@import 'blocks/advantage-card';
@import 'blocks/promo-block';
@import 'blocks/promo-block-card';
@import 'blocks/services';
@import 'blocks/services-card';
@import 'blocks/header-links';
@import 'blocks/advice-block';
@import 'blocks/about-us';
@import 'blocks/card-popup';
@import 'blocks/advice-card';
@import 'blocks/custom-select';
@import 'blocks/callback-form';
@import 'blocks/callback-success';
@import 'blocks/catalog-page';
@import 'blocks/catalog-lvl';
@import 'blocks/page-title';
@import 'blocks/breadcrumbs';
@import 'blocks/buttons';
@import 'blocks/simple-pagination';
@import 'blocks/pagination';
@import 'blocks/custom-checkbox';
@import 'blocks/range-slider';
@import 'blocks/article-card';
@import 'blocks/modals-styles';
@import 'blocks/favorites';
@import 'blocks/basket';

// используйте это для отключения ховеров на тач устройствах
// ! но не используйте это для текстовых полей ( input, textarea )
// также для любых transition обязательно указывайте transition-property
// transition: color $default-transition-settings, а не transition: $default-transition-settings

/* @media (hover: hover), screen and (min-width: 0\0) {
  &:hover,
  &:focus {
    color: red;
  }
} */
