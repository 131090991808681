.page-title {
  font-weight: bold;
  font-size: 34px;
  line-height: 40px;
  margin: 0;
  margin-bottom: 40px;
  max-width: 800px;

  @include vp--767 {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 30px;
  }

  @include vp--374 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 20px;
  }
}