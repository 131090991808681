.page {
  padding-top: 275px;  

  @include vp--1279 {
    padding-top: 281px;
  }

  @include vp--959 {
    padding-top: 185px;
  }

  @include vp--639 {
    padding-top: 100px;
  }

  &.page-padding {
    padding-top: 235px;

    @include vp--1279 {
      padding-top: 241px;
    }
  
    @include vp--959 {
      padding-top: 155px;
    }
  
    @include vp--639 {
      padding-top: 80px;
    }
  }

  &.page-without-header {
    padding-top: 0;
  }

  .custom-select {
    padding: 0;
    width: 240px;

    @include vp--767 {
      width: 203px;
    }

    @include vp--639 {
      width: 100%;
    }

    .custom-select__placeholder {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $color-forest-green;
      left: 15px;

      @include vp--767 {
        font-size: 14px;
        line-height: 22px;
      }

      @include vp--639 {
        left: 40%;
        transform: translate(-40%, -50%);
      }
    }

    .custom-select__button {
      height: 50px;
      background: rgba(109, 168, 12, 0.1);
      border-radius: 2px;
      border: none;
      padding: 5px 25px;

      .custom-select__icon {
        width: 8px;
        height: 5px;
        background-color: $color-forest-green;
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        transform: rotate(180deg);
        transition: transform $trans--300;
        will-change: transform;

        @include vp--639 {
          margin-left: unset;
        }
      }

      .custom-select__text {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: $color-forest-green;
      }

      @include vp--767 {
        height: 40px;
        font-size: 14px;
        line-height: 24px;
        padding: 5px 15px;
      }
    }

    .custom-select__list {
      padding: 6px 0;
      bottom: 0;
      max-height: 266px;
      border: none;
      border-radius: 2px;
      box-shadow: 0 4px 10px 0 rgba($color-default-black, 0.05);
    }

    .custom-select__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 5px 0 6px;
      padding: 13px 9px 9px 9px;
      font-size: 16px;
      line-height: 26px;
      color: $color-black;
      border-top: 1px solid $color-whisper;

      &:first-of-type {
        border-top: none;
      }
  
      @include vp--767 {
        font-size: 14px;
        line-height: 24px;
        padding: 8px 15px 8px 0;
      }
  
      &[aria-selected='true'] {
        border: none;
  
        & + .custom-select__item {
          border: none;
        }
      }
    }

    &.is-open {
      .custom-select__button {
        .custom-select__icon {
          transform: rotate(0);
        }
      }  
    }

    .custom-input {
      display: none;
    }

    &.town-select {
      .custom-input {
        display: flex;
  
        input {
          flex: 1;
          width: unset;
          height: 50px !important;
          margin: 0 15px;
          margin-top: 15px;

          @include vp--959 {
            height: 40px !important;
          }

          @include vp--767 {
            margin: 0 6px;
            margin-top: 5px;
          }
        }
      }
    }
  }
}
