.custom-input input {
  height: 50px;
  padding: 8px 15px;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  background-color: $color-default-white;
  border-radius: 2px;
  border: 1px solid rgba($color: $color-black, $alpha: 0.1);
  outline: none;
  transition: background-color $trans--300,
    border-color $trans--300;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover {
      background-color: $color-saltpan;
    }
  }

  &:focus {
    background-color: $color-default-white;
    border-color: $color-forest-green-2;
  }

  @include vp--639 {
    height: 48px;
    font-size: 14px;
  }
}

.custom-input--christi input {
  width: 96px;
  color: $color-forest-green;
  border: none;
  line-height: 22px;
  border-radius: 0;
  text-align: center;
  background-color: rgba($color: $color-christi, $alpha: 0.1);
  transition: color $trans--300;

  @include vp--1279 {
    width: 81px;
  }

  @include vp--959 {
    width: 72px;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover {
      background-color: rgba($color: $color-christi, $alpha: 0.1);
    }
  }

  &:focus {
    background-color: rgba($color: $color-christi, $alpha: 0.1);
    color: $color-forest-green;
  }

  cursor: pointer;
}

.custom-input--christi-header input {
  width: 438px;
  border-radius: 3px 0 0 3px;
  text-align: start;
  z-index: 5;
  color: $color-black;

  @include vp--1279 {
    width: 260px;
  }

  @include vp--959 {
    width: 100%;
    height: 40px;
  }

  @include vp--639 {
    display: none;
    padding: 11px 15px;
    height: 40px;
    font-size: 13px;
    line-height: 18px;
  }

  &:focus {
    color: $color-black;
  }
}

.custom-input--bigger {
  @include vp--639 {
    width: 100%;
  }

    input {
    width: 156px;
    height: 50px;

    @include vp--1279 {
      width: 156px;
    }

    @include vp--959 {
      width: 156px;
    }

    @include vp--959 {
      height: 46px;
    }

    @include vp--639 {
      width: 100%;
      height: 46px;
    }
  }
}

.custom-input--radio {
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  input:disabled + .button--price {
    opacity: 0.5;
    color: $color-forest-green;
    background-color: rgba($color: $color-christi, $alpha: 0.1);
    pointer-events: none;
  }

  .custom-input--disabled .button--price {
    opacity: 0.5;
    color: $color-forest-green;
    background-color: rgba($color: $color-christi, $alpha: 0.1);
    pointer-events: none;
  }

  label {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 4px 5px;
    border-radius: 5px;
    border: 1px solid rgba($color: $color-forest-green-3, $alpha: 0.1);
    transition: all $trans--300;
    cursor: pointer;

    @include vp--639 {
      outline: none;
      padding: 4px 3px;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover .button--price,
      &:focus .button--price {
        color: $color-forest-green-2;
      }
    }
  }

  input:checked + label {
    border: 1px solid $color-forest-green;
    box-shadow: 0 0 0 1px $color-forest-green;
  }

  p {
    margin: 0;
    text-align: center;
  }
}

.custom-input--color-transparent input {
  background-color: $color-transparent;
}

.custom-input--search input {
  background-color: $color-saltpan;
  border: none;
  border-radius: 2px 0 0 2px;

  &:focus {
    background-color: $color-saltpan;
  }
}
